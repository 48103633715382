import { ArrowPathIcon } from '@heroicons/react/24/solid';
// import { HouseTypeLabels } from '@ocenkatech/common/const';
import {
    useApiV1ReportsGenerateCreateMutation,
    useApiV1ReportsRefillCreateMutation,
    useApiV1ReportsRetrieveQuery,
} from '@ocenkatech/common/api';
import { classnames } from '@ocenkatech/common/lib';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { HeadingActions } from '@ocenkatech/common/ui/Heading';
import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import { PdfView } from '@ocenkatech/common/ui/PdfView';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReportStatuses } from 'entities/reports';

export const Report = () => {
    const [isGenerating, setGenerating] = useState<boolean | null>(null);

    const { id } = useParams<{ id: string }>();

    const {
        isLoading,
        currentData: report,
        refetch: refetchReport,
    } = useApiV1ReportsRetrieveQuery(
        { id: +id! },
        { pollingInterval: isGenerating ? 2000 : 0 },
    );

    const [generate, { isLoading: isGenerateStarting }] =
        useApiV1ReportsGenerateCreateMutation();

    useEffect(() => {
        if (!isLoading && report) {
            setGenerating(report.status === ReportStatuses.GENERATING);
        }
    }, [isLoading, report]);

    const generateReport = useCallback(async () => {
        if (report) {
            await generate(report);
            await refetchReport();
        }
    }, [report, generate, refetchReport]);

    const [refillMutation] = useApiV1ReportsRefillCreateMutation();

    const refill = useCallback(async () => {
        if (id) {
            await refillMutation({ id: +id }).unwrap();
            await refetchReport();
        }
    }, [refillMutation, id, refetchReport]);

    return (
        <>
            <HeadingActions title={'Отчет №' + id} />
            <div className="white mx-auto max-w-7xl bg-white px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                {isLoading ? <div>Загрузка...</div> : null}
                {report ? (
                    <div className="grid gap-4">
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <LinkApp
                                    to={`/selection/${report?.selection}`}
                                    className="font-bold">
                                    {'<-'} К подбору
                                </LinkApp>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Вид объекта:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {
                                            // HouseTypeLabels[
                                            //     report.selection.houseType!
                                            // ]
                                        }
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Адрес:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {/* {report.selection.address} */}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Этаж:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {/* {report.selection.floor} /{' '}
                                        {report.selection.maxFloor} */}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Кол-во комнат:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {/* {report.selection.roomsCount} */}
                                    </span>
                                </div>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Площадь:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {/* {report.selection.area} */}
                                    </span>
                                </div>
                                <h3 className="mb-1 mt-1 text-sm font-bold">
                                    Результат оценки
                                </h3>
                                <div>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Рыночная стоимость объекта:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {/* {report.selection.price} ₽ */}
                                    </span>
                                </div>
                                <div className={'mb-4'}>
                                    <span
                                        className={
                                            'text-sm font-medium text-gray-800'
                                        }>
                                        Средневзвешенная стоимость 1 кв. м.:
                                    </span>
                                    <span
                                        className={
                                            'ml-1 text-sm font-bold text-black'
                                        }>
                                        {/* {report.selection.pricePerMeter} ₽ */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <Button onClick={refill}>
                            Синхронизировать с заявкой
                        </Button>

                        {report?.inspection && (
                            <Link
                                to={
                                    '/inspections/' +
                                    report.inspection +
                                    '/view'
                                }
                                className={
                                    'block rounded-md bg-white px-2.5 py-1.5 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                                }>
                                Перейти к осмотру
                            </Link>
                        )}
                        {report?.document && (
                            <PdfView
                                fileUrl={report.document}
                                className={'w-1/2'}
                            />
                        )}
                        <div>
                            {report?.document && (
                                <Button>
                                    <a
                                        href={report.document}
                                        download={`report_${report.id}.pdf`}
                                        rel="noreferrer">
                                        Скачать отчет
                                    </a>
                                </Button>
                            )}
                            {report?.rawDocument && (
                                <a
                                    href={report.rawDocument}
                                    download={`report_${report.id}.docx`}
                                    rel="noreferrer">
                                    <Button theme={ButtonTheme.SECONDARY}>
                                        Скачать отчет в формате Word
                                    </Button>
                                </a>
                            )}
                            <Button
                                theme={ButtonTheme.GREY}
                                onClick={generateReport}
                                icon={true}
                                loading={isGenerateStarting}>
                                <ArrowPathIcon
                                    className={classnames('h-5 w-5', {
                                        'animate-spin':
                                            report?.status ===
                                            ReportStatuses.GENERATING,
                                    })}
                                />
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};
