import { Card } from '@ocenkatech/common/ui/Card';
import { FC, memo } from 'react';

interface ActivityFeedCardProps {
    isLoading?: boolean;
    className?: string;
}

export const ActivityFeedCard: FC<ActivityFeedCardProps> = memo(
    ({ className }) => {
        return (
            <>
                <Card className={className}>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Лента активности
                    </h3>
                    <div className="pt-6"></div>
                </Card>
            </>
        );
    },
);
