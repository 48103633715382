import {
    UploadFileRead,
    useFilesUploadCompleteCreateMutation,
    useFilesUploadCreateMutation,
} from '@ocenkatech/common/api';
import { useState, useCallback } from 'react';

export function useUploadFiles(): [
    (file: File) => Promise<UploadFileRead>,
    { isLoading: boolean },
] {
    const [isLoading, setIsLoading] = useState(false);
    const [uploadCreate] = useFilesUploadCreateMutation();
    const [uploadComplete] = useFilesUploadCompleteCreateMutation();

    const upload = useCallback(
        async (file: File) => {
            setIsLoading(true);
            const respUploadCreate = await uploadCreate({
                name: file.name,
            }).unwrap();

            const { url, fields } = respUploadCreate.params;
            await uploadFile(url, fields, file);
            setIsLoading(false);

            return await uploadComplete({
                id: respUploadCreate.id,
            }).unwrap();
        },
        [uploadComplete, uploadCreate],
    );
    return [upload, { isLoading }];
}

async function uploadFile(url: string, fields: any, file: File) {
    const fd = new FormData();
    Object.keys(fields).forEach((key) => {
        fd.set(key, fields[key]);
    });
    fd.set('file', file);

    const response = await fetch(url, {
        method: 'POST',
        body: fd,
        mode: 'no-cors',
    });

    return response.text();
}
