import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { OrderReportRead } from '@ocenkatech/common/api';
import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import { Table } from '@ocenkatech/common/ui/Table';
import { FC } from 'react';
import { ReportStatusesLabels } from '../model/consts';

interface TableDocumentsProps {
    report: Omit<OrderReportRead, 'selection'>;
}

export const TableDocuments: FC<TableDocumentsProps> = ({ report }) => {
    return (
        <Table
            rows={[report].map((r) => ({
                ...r,
                status: r.status && ReportStatusesLabels[r.status],
                appraiserName: r.appraiser,
            }))}
            columns={[
                { key: 'id' },
                { key: 'appraiserName' },
                { key: 'status' },
            ]}
            columnsName={['#', 'Оценщик', 'Статус']}>
            {(report) => (
                <>
                    {report.document ? (
                        <LinkApp
                            to={report.document}
                            target="_blank"
                            className="block">
                            <ArrowDownTrayIcon className="mr-1.5 inline h-5 w-5" />
                            Скачать
                        </LinkApp>
                    ) : // <a
                    null}
                    <LinkApp to={'/reports/' + report.id}>
                        Редактировать
                    </LinkApp>
                    <div className="cursor-pointer text-red-600 hover:text-red-900">
                        Удалить
                    </div>
                </>
            )}
        </Table>
    );
};
