import { classnames } from '@ocenkatech/common/lib';
import { Card } from '@ocenkatech/common/ui/Card';
import { DataList } from '@ocenkatech/common/ui/DataList';
import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import { FC } from 'react';

interface InfoMassSelectionCardProps {
    className?: string;
}

export const InfoMassSelectionCard: FC<InfoMassSelectionCardProps> = ({
    className = '',
}) => {
    return (
        <Card
            className={classnames(
                className,
                'flex flex-col justify-items-end sm:!pt-0',
            )}>
            <DataList
                className="grow"
                items={[
                    { name: 'Кол-во объектво для расчёта', value: '10' },
                    { name: 'Статус расчёта', value: 'в процессе' },
                ]}
            />
            <LinkApp to={'/'} download>
                Результирующий файл
            </LinkApp>
        </Card>
    );
};
