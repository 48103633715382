import { Checkbox } from '@ocenkatech/common/ui/Checkbox';
import { InputForm } from '@ocenkatech/common/ui/Input';
import { SelectForm } from '@ocenkatech/common/ui/Select';
import { get } from 'lodash';
import {
    UseFormRegister,
    FieldValues,
    FieldErrors,
    FieldError,
} from 'react-hook-form';
import { Section } from '../../ui/Section';
import { SectionProps } from './types';

export type GenerateFormProps<T extends FieldValues> = {
    sections: SectionProps<T>[];
    register: UseFormRegister<T>;
    errors: FieldErrors<T>;
};

export function generateForm<T extends FieldValues>({
    sections,
    register,
    errors,
}: GenerateFormProps<T>): JSX.Element {
    return (
        <div className="space-y-8 [&_div:last-child]:border-b-0">
            {sections.map((section, index) => (
                <Section
                    title={section.title}
                    subtitle={section.subtitle}
                    key={index}>
                    {section.fields?.map((field, indexField) => {
                        if (field.type === 'checkbox') {
                            return (
                                <Checkbox
                                    key={indexField}
                                    {...field.props}
                                    register={register}
                                    error={
                                        get(
                                            errors,
                                            field.props.formKey,
                                        ) as FieldError
                                    }
                                />
                            );
                        } else if (field.type === 'select') {
                            return (
                                <SelectForm
                                    key={indexField}
                                    {...field.props}
                                    register={register}
                                    error={
                                        get(
                                            errors,
                                            field.props.formKey,
                                        ) as FieldError
                                    }
                                />
                            );
                        }
                        return (
                            <InputForm
                                key={indexField}
                                {...field.props}
                                register={register}
                                attrs={{
                                    ...register(field.props.formKey),
                                    ...field.props.attrs,
                                }}
                                error={
                                    get(
                                        errors,
                                        field.props.formKey,
                                    ) as FieldError
                                }
                            />
                        );
                    })}
                </Section>
            ))}
        </div>
    );
}
