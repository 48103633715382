import { classnames } from '@ocenkatech/common/lib';
import { FieldChildrenProps } from '@ocenkatech/common/ui/Field';
import React, { ChangeEvent, useCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import InputMask from 'react-input-mask';

type SelectFieldProps<T extends FieldValues> = FieldChildrenProps<T> & {
    options: { value: string; name: string }[];
    emptyValue?: string;
};

export const renderSelect = <T extends FieldValues>({
    formKey,
    label,
    options,
    register,
    hint = '',
    emptyValue = '',
    error,
    isLoading,
}: SelectFieldProps<T>) => {
    const htmlFor = formKey.replace('.', '_');
    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label
                htmlFor={htmlFor}
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                {label}
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                {isLoading ? (
                    <div className="h-9 w-full animate-pulse rounded bg-gray-200 sm:max-w-xs" />
                ) : (
                    <select
                        {...register(formKey)}
                        name={htmlFor}
                        className={classnames(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6',
                            {
                                'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                                    !!error,
                            },
                        )}
                        defaultValue={''}>
                        {emptyValue !== '' && (
                            <option value="" disabled>
                                {emptyValue}
                            </option>
                        )}
                        {options?.map((value) => (
                            <option key={value.value} value={value.value}>
                                {value.name}
                            </option>
                        ))}
                    </select>
                )}
                {hint !== '' && (
                    <p className="mt-3 text-sm leading-6 text-gray-600">
                        {hint}
                    </p>
                )}
                {error && (
                    <p className="mt-3 text-sm leading-6 text-red-600">
                        {error.type === 'required'
                            ? 'Это поле обязательное для заполнения'
                            : error.message}
                    </p>
                )}
            </div>
        </div>
    );
};

export const renderInput = <T extends FieldValues>({
    register,
    formKey,
    label,
    type = 'text',
    props = {},
    error,
}: InputFieldProps<T>) => {
    const htmlFor = formKey.replace('.', '_');
    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label
                htmlFor={htmlFor}
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                {label}
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <input
                    {...props}
                    type={type}
                    {...register(formKey)}
                    name={htmlFor}
                    className={classnames(
                        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6',
                        {
                            'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                                !!error,
                        },
                    )}
                />
                {error && (
                    <p className="mt-3 text-sm leading-6 text-red-600">
                        {error.type === 'required'
                            ? 'Это поле обязательное для заполнения'
                            : error.message}
                    </p>
                )}
            </div>
        </div>
    );
};

export const MaskInput = <T extends FieldValues>({
    mask,
    register,
    formKey,
    label,
    type = 'text',
    props = {},
    mapValue = (i) => i,
}: InputMaskProps<T>) => {
    const registerProps = register(formKey);
    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            return registerProps.onChange({
                ...e,
                type: e.type,
                target: {
                    ...e.target,
                    value: mapValue(e.target.value),
                },
            });
        },
        [mapValue, registerProps],
    );

    const htmlFor = formKey.replace('.', '_');
    return (
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
            <label
                htmlFor={htmlFor}
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                {label}
            </label>
            <div className="mt-2 sm:col-span-2 sm:mt-0">
                <InputMask
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    mask={mask}
                    alwaysShowMask={true}
                    {...props}
                    type={type}
                    {...registerProps}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

type InputFieldProps<T extends FieldValues> = FieldChildrenProps<T> & {
    type?: 'text' | 'number' | 'password';
    props?: React.InputHTMLAttributes<HTMLInputElement>;
};

export type InputMaskProps<T extends FieldValues> = InputFieldProps<T> & {
    mask: string;
    mapValue?: (i: string) => string;
};
