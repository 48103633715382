import { CustomerWrite, PatchedCustomerWrite } from '@ocenkatech/common/api';
import { ErrorMessageLabel, PatternValidation } from '@ocenkatech/common/const';
import {
    inputMasks,
    useHandleErrors,
    validationMaxDate,
} from '@ocenkatech/common/lib';
import { AddPropIsLoading } from '@ocenkatech/common/ui/AddPropIsLoading';
import { Form } from '@ocenkatech/common/ui/Form';
import { InputForm } from '@ocenkatech/common/ui/Input';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { format } from 'date-fns';
import { FC, useEffect } from 'react';
import { SubmitHandler, UseFormProps, useForm } from 'react-hook-form';

type CustomerFormProps = UseFormProps<CustomerWrite> & {
    onSave: SubmitHandler<CustomerWrite>;
    formId?: string;
    error?: FetchBaseQueryError | SerializedError;
    isLoading?: boolean;
    isProgress?: boolean;
    isView?: boolean;
    defaultValues?: PatchedCustomerWrite;
};

export const CustomerForm: FC<CustomerFormProps> = ({
    onSave,
    formId,
    isLoading = false,
    isProgress,
    error,
    isView,
    defaultValues,
    ...propsUseForm
}) => {
    const {
        handleSubmit,
        register,
        reset,
        setError,
        formState: { errors },
    } = useForm<CustomerWrite>(propsUseForm);

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues);
        }
    }, [defaultValues, reset]);

    useHandleErrors(error, setError, true);

    return (
        <Form
            id={formId}
            inProgress={isProgress}
            onSubmit={!isView ? handleSubmit(onSave) : undefined}>
            <div className="grid gap-4 sm:grid-cols-6">
                <AddPropIsLoading isLoading={isLoading}>
                    <InputForm
                        formKey="name"
                        label="Имя"
                        error={errors.name}
                        register={register}
                        attrs={{
                            ...register('name', { required: true }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey="email"
                        label="Email"
                        error={errors.email}
                        register={register}
                        attrs={{
                            ...register('email', {
                                required: true,
                                pattern: {
                                    value: PatternValidation['email'],
                                    message: ErrorMessageLabel.email,
                                },
                                disabled: isView,
                            }),
                        }}
                        required
                    />
                    <InputForm
                        formKey="phone"
                        label="Телефон"
                        error={errors.phone}
                        register={register}
                        mask={inputMasks.phoneNumber}
                        attrs={{
                            ...register('phone', { required: true }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey="passport.fio"
                        label="Фамилия Имя Отчество"
                        error={errors.passport?.fio}
                        register={register}
                        attrs={{
                            ...register('passport.fio', { required: true }),
                            disabled: isView,
                        }}
                        fieldClassName="col-start-1"
                        required
                    />
                    <InputForm
                        formKey="passport.birthday"
                        label="Дата рождения"
                        type="date"
                        error={errors.passport?.birthday}
                        register={register}
                        attrs={{
                            ...register('passport.birthday', {
                                required: true,
                                validate: (value) => {
                                    return validationMaxDate(
                                        new Date(),
                                        String(value),
                                    );
                                },
                            }),
                            max: format(new Date(), 'yyyy-MM-dd'),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.passportSeries'}
                        label={'Серия паспорта'}
                        register={register}
                        error={errors.passport?.passportSeries}
                        mask={inputMasks.passportSeries}
                        attrs={{
                            ...register('passport.passportSeries', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.passportNumber'}
                        label={'Номер паспорта'}
                        register={register}
                        error={errors.passport?.passportNumber}
                        mask={inputMasks.passportNumber}
                        attrs={{
                            ...register('passport.passportNumber', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.passportIssuer'}
                        label={'Кем выдан'}
                        register={register}
                        error={errors.passport?.passportIssuer}
                        size={'xl'}
                        attrs={{
                            ...register('passport.passportIssuer', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.passportIssueDate'}
                        label={'Дата выдачи'}
                        register={register}
                        type="date"
                        error={errors.passport?.passportIssueDate}
                        attrs={{
                            ...register('passport.passportIssueDate', {
                                required: true,
                                validate: (value) => {
                                    return validationMaxDate(
                                        new Date(),
                                        String(value),
                                    );
                                },
                            }),
                            max: format(new Date(), 'yyyy-MM-dd'),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.passportDepartmentCode'}
                        label={'Код подразделения'}
                        register={register}
                        error={errors.passport?.passportDepartmentCode}
                        mask="999-999"
                        attrs={{
                            ...register('passport.passportDepartmentCode', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.birthAddress'}
                        label={'Адрес рождения'}
                        register={register}
                        error={errors.passport?.birthAddress}
                        size={'xl'}
                        attrs={{
                            ...register('passport.birthAddress', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        required
                    />
                    <InputForm
                        formKey={'passport.registrationAddress'}
                        label={'Адрес регистрации'}
                        register={register}
                        error={errors.passport?.registrationAddress}
                        size={'xl'}
                        attrs={{
                            ...register('passport.registrationAddress', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        required
                    />
                </AddPropIsLoading>
            </div>
        </Form>
    );
};
