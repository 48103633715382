import { Card } from '@ocenkatech/common/ui/Card';
import { FC } from 'react';
import { DropFile } from 'shared/ui/DropFile';

interface UploadMassSelectionCardProps {}

export const UploadMassSelectionCard: FC<UploadMassSelectionCardProps> = () => {
    return (
        <Card>
            <DropFile
                formKey="file"
                label="Загрузить файл"
                onUpload={() => {}}
                onRemove={() => {}}
                titleAccept="CSV до 10 мегабайт"
                accept=".csv"
            />
        </Card>
    );
};
