import { PlusIcon } from '@heroicons/react/20/solid';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import {
    PatchedInspectionRead,
    Status251Enum,
    useApiV1InspectionsListQuery,
} from '@ocenkatech/common/api';
import { useSaveUrlParams, handleError } from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import { ErrorMessage } from '@ocenkatech/common/ui/ErrorMessage';
import { HeadingTabs } from '@ocenkatech/common/ui/Heading';
import { Pagination } from '@ocenkatech/common/ui/Pagination';
import { Table } from '@ocenkatech/common/ui/Table';
import React, { useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { InspectionStatusLabels } from '../consts/utils';

const pageSize = 10;

export const InspectionTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState<Status251Enum | ''>(
        'requested',
    );
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [
            { name: 'status', value: filterStatus, setValue: setFilterStatus },
            { name: 'page', value: page, setValue: setPage },
        ],
        [filterStatus, page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });

    const {
        currentData: inspections,
        isFetching,
        isLoading,
        error,
    } = useApiV1InspectionsListQuery({
        page,
        pageSize,
        status: filterStatus as Status251Enum,
    });

    const totalsInspections = useRef<number>();
    const filterTabs = Object.entries(InspectionStatusLabels).map(
        ([status, name]) => ({
            name,
            value: status,
        }),
    );

    const applicationsWithCustomerName = useMemo(
        () =>
            inspections?.results?.map((i: PatchedInspectionRead) => ({
                ...i,
                statusLabel: InspectionStatusLabels[i.status!],
            })) ?? [],
        [inspections?.results],
    );

    if (
        inspections?.count &&
        totalsInspections.current !== inspections?.count
    ) {
        totalsInspections.current = inspections.count;
    }

    if (error) {
        return (
            <ErrorMessage
                message={handleError(error)}
                className="bg-white py-8"
            />
        );
    }

    return (
        <>
            <div className="bg-white pb-6 pt-10">
                <div className="px-4 sm:px-6 lg:px-8">
                    <HeadingTabs
                        namePage="Осмотры"
                        tabs={filterTabs}
                        currentTab={filterStatus}
                        onChangeTab={setFilterStatus}
                    />
                </div>
            </div>
            {inspections?.count === 0 ? (
                <div className="bg-white px-4 py-12">
                    <div className="text-center">
                        <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Осмотров нет
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Начните с создания нового заказа.
                        </p>
                        <div className="mt-6">
                            <Link to={'/applications/new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новый заказ
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <Table
                            isLoading={isFetching}
                            rows={applicationsWithCustomerName}
                            columns={[
                                { key: 'id', className: 'w-[1%]' },
                                // { key: 'order', className: 'w-[1%]' },
                                // { key: 'address' },
                                // {
                                //     key: 'customer',
                                //     className: 'w-1/12 whitespace-nowrap',
                                // },
                                {
                                    key: 'statusLabel',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                            ]}
                            columnsName={[
                                '#',
                                // 'Заявка',
                                // 'Адрес',
                                // 'Заказчик',
                                'Статус',
                            ]}
                            onClick={(el) => {
                                el.status === 'requested'
                                    ? navigate(`/inspections/${el.id}/edit`)
                                    : navigate(`/inspections/${el.id}/view`);
                            }}
                        />
                    </div>
                    <Pagination
                        isLoading={isLoading}
                        onPageChange={setPage}
                        totalItems={
                            totalsInspections.current || inspections?.count
                        }
                        currentPage={page}
                        itemsPerPage={pageSize}
                    />
                </>
            )}
        </>
    );
};
