'use client';
import { jsx, Fragment } from 'react/jsx-runtime';
import React, { Children, cloneElement } from 'react';

var AddPropIsLoading = function (_a) {
    var children = _a.children, isLoading = _a.isLoading;
    return (jsx(Fragment, { children: Children.map(children, function (child) {
            return child &&
                typeof child.type !== 'string' &&
                React.isValidElement(child)
                ? cloneElement(child, { isLoading: isLoading })
                : child;
        }) }));
};

export { AddPropIsLoading };
