import { baseQuery } from '@ocenkatech/common/api';
import { KEEP_LOGGED_ID, TOKEN_KEY } from '@ocenkatech/common/const';
import { saveInStorage } from '@ocenkatech/common/lib';
import { tokenActions } from '@ocenkatech/common/models';
import { createApi } from '@reduxjs/toolkit/query/react';

type Credentials = {
    username: string;
    password: string;

    keepLoggedIn: boolean;
};

type Token = {
    token: string;
};

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation<Token, Credentials>({
            query: (body) => ({
                url: '/api/v1/auth/token/',
                method: 'POST',
                body: { username: body.username, password: body.password },
            }),
            extraOptions: {
                skipAuth: true,
            },
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(tokenActions.setToken(data.token));
                    saveInStorage(
                        KEEP_LOGGED_ID,
                        String(arg.keepLoggedIn),
                        true,
                    );
                    saveInStorage(TOKEN_KEY, data.token, arg.keepLoggedIn);
                } catch (error) {}
            },
        }),
    }),
});

export const { useLoginMutation } = authApi;
