import { Combobox, Option } from '@ocenkatech/common/ui/Combobox';
import { FC } from 'react';

export type Field = {
    label: string;
    name: string;
    options: (string | Option)[];
    value?: string | number | null;
};
interface AppraiserEditProps {
    fields?: Field[];
    onChange?: (arg: Record<string, number | null>) => Promise<void>;
    isLoading?: boolean;
}

export const AppraiserEdit: FC<AppraiserEditProps> = ({
    fields,
    onChange,
    isLoading,
}) => {
    return (
        <dl className="divide-y divide-gray-100">
            {fields?.map((field, index) => (
                <div
                    key={index}
                    className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">
                        {field.label}
                    </dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <Combobox
                            label=""
                            options={field.options}
                            value={field?.value}
                            onChange={(value) => {
                                onChange?.({
                                    [field.name]: Number(
                                        typeof value === 'object'
                                            ? value.value
                                            : value,
                                    ),
                                });
                            }}
                            isLoading={isLoading}
                        />
                    </dd>
                </div>
            ))}
        </dl>
    );
};
