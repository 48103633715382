import { AddPropIsLoading } from '@ocenkatech/common/ui/AddPropIsLoading';
import React, { PropsWithChildren } from 'react';
import { Skeleton } from './Skeleton';

export type GroupProps = {
    title?: string;
    subtitle?: string;
    isLoading?: boolean;
    className?: string;
};

export const Section: React.FC<PropsWithChildren<GroupProps>> = ({
    title,
    subtitle,
    children,
    isLoading = false,
    className = '',
}) => {
    if (isLoading) {
        return (
            <Skeleton title={title} subtitle={subtitle}>
                <AddPropIsLoading isLoading={isLoading}>
                    {children}
                </AddPropIsLoading>
            </Skeleton>
        );
    }

    return (
        <div
            className={`grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3 ${className}`}>
            {(title || subtitle) && (
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {title}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {subtitle}
                    </p>
                </div>
            )}
            <div className="grid-cols-1 gap-x-6 gap-y-8 sm:grid sm:grid-cols-6 md:col-span-2">
                <AddPropIsLoading isLoading={isLoading}>
                    {children}
                </AddPropIsLoading>
            </div>
        </div>
    );
};
