import {
    AssessmentObject,
    ReadAssessmentObjectRead,
    DocumentsGroupRead,
    DocumentsGroupTypeEnum,
    UploadFileRead,
} from '@ocenkatech/common/api';
import {
    LayoutTypeLabels,
    SummerRoomsTypeLabels,
} from '@ocenkatech/common/const';
import { useHandleErrors } from '@ocenkatech/common/lib';
import { AddPropIsLoading } from '@ocenkatech/common/ui/AddPropIsLoading';
import { Form } from '@ocenkatech/common/ui/Form';
import { InputForm } from '@ocenkatech/common/ui/Input';
import { SelectForm } from '@ocenkatech/common/ui/Select';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, useCallback, useEffect, useMemo } from 'react';
import {
    Controller,
    FormProvider,
    SubmitHandler,
    UseFormProps,
    useForm,
} from 'react-hook-form';
import { AddressAutocomplete } from 'features/autoComplete';
import { ControllerDocumentsGroup } from 'features/documents';
import { AssessmentObjectTypeLabels } from 'entities/assessmentObject';
import { Suggest } from 'entities/buildings';
import { DropFile } from 'shared/ui/DropFile';
import { ResidentialComplex } from './ResidentialComplex';

interface AssessmentObjectFormProps
    extends Omit<UseFormProps<AssessmentObject>, 'defaultValues'> {
    onSave: SubmitHandler<AssessmentObject>;
    city?: number | null;
    formId?: string;
    error?: FetchBaseQueryError | SerializedError;
    isLoading?: boolean;
    isProgress?: boolean;
    isView?: boolean;
    defaultValues?: ReadAssessmentObjectRead;
}

export const AssessmentObjectForm: FC<AssessmentObjectFormProps> = ({
    onSave,
    city,
    formId,
    isLoading = false,
    isProgress,
    isView,
    error,
    defaultValues,
    ...propsUseForm
}) => {
    const documents = useMemo<
        Record<DocumentsGroupTypeEnum, DocumentsGroupRead[]>
    >(
        () =>
            defaultValues?.documents.reduce<
                Record<DocumentsGroupTypeEnum, DocumentsGroupRead[]>
            >(
                (acc, docGroup) => {
                    if (docGroup?.type) {
                        acc[docGroup.type].push(docGroup);
                    }
                    return acc;
                },
                {
                    tech: [],
                    legal: [],
                },
            ) ?? { legal: [], tech: [] },
        [defaultValues?.documents],
    );

    const methods = useForm<AssessmentObject>({
        ...propsUseForm,
        values: {
            ...defaultValues,
            documents: defaultValues?.documents?.map((docGroup) => docGroup.id),
        },
    });
    const {
        handleSubmit,
        register,
        control,
        watch,
        setValue,
        setError,
        formState: { errors },
    } = methods;

    const [area, summerRoomArea, livingArea, documentsGroupsId = []] = watch([
        'area',
        'summerRoomArea',
        'livingArea',
        'documents',
    ]);

    useHandleErrors<AssessmentObject>(error, setError, true);

    const onCompleteAddress = useCallback(
        (data: Suggest) => {
            setValue('address', data.value);
            setValue('subjectRf', data.data.region);
            setValue('locality', data.data.city);
            setValue('dadata', data);
            // setValue('houseFiasId', data.data.houseFiasId);
        },
        [setValue],
    );

    const onAfterAddGroup = useCallback(
        (group: DocumentsGroupRead) => {
            setValue('documents', [...documentsGroupsId, group.id]);
        },
        [documentsGroupsId, setValue],
    );

    const onAfterDeletedGroup = useCallback(
        (id: number) => {
            setValue(
                'documents',
                documentsGroupsId?.filter((gId) => gId !== id) ?? [],
            );
        },
        [documentsGroupsId, setValue],
    );

    const onUploadedFloorPlan = useCallback(
        (d: UploadFileRead) => {
            setValue('floorPlanBti', d.id);
        },
        [setValue],
    );

    const onRemovedFloorPlan = useCallback(() => {
        setValue('floorPlanBti', null);
    }, [setValue]);

    useEffect(() => {
        if (area || summerRoomArea) {
            setValue(
                'areaWithSummerRooms',
                Number(area) + Number(summerRoomArea),
            );
        } else {
            setValue('areaWithSummerRooms', null);
        }
    }, [area, summerRoomArea, setValue]);

    useEffect(() => {
        if (area || livingArea) {
            setValue('utilityRoomsArea', Number(area) - Number(livingArea));
        } else {
            setValue('utilityRoomsArea', null);
        }
    }, [area, livingArea, setValue]);

    return (
        <Form
            id={formId}
            inProgress={isProgress}
            onSubmit={!isView ? handleSubmit(onSave) : undefined}>
            <div className="grid-cols-1 gap-x-6 gap-y-8 sm:grid sm:grid-cols-6">
                <AddPropIsLoading isLoading={isLoading}>
                    <SelectForm
                        formKey="type"
                        label="Тип объекта оценки"
                        register={register}
                        options={
                            Object.entries(AssessmentObjectTypeLabels).map(
                                ([value, name]) => ({ name, value }),
                            ) ?? []
                        }
                        emptyValue={'Выберите значение'}
                        error={errors.type}
                        attrs={{
                            ...register('type', { required: true }),
                            disabled: isView,
                        }}
                        required
                    />
                    {!isView && (
                        <>
                            <FormProvider {...methods}>
                                <ResidentialComplex
                                    city={city}
                                    isLoading={isLoading}
                                />
                            </FormProvider>
                            <AddressAutocomplete
                                value={
                                    defaultValues?.dadata?.data
                                        ? (defaultValues?.dadata as Suggest)
                                        : undefined
                                }
                                onChangeFiasData={onCompleteAddress}
                                minFiasLevel={9}
                                message={'Введите данные до номера квартиры'}
                            />
                        </>
                    )}
                    <InputForm
                        formKey={'address'}
                        label={'Адрес'}
                        register={register}
                        error={errors.address}
                        required={true}
                        attrs={{
                            ...register('address', { required: true }),
                            disabled: isView,
                        }}
                        size={'xl'}
                    />
                    <InputForm
                        formKey={'residentialComplexName'}
                        label={'Название ЖК'}
                        register={register}
                        error={errors.residentialComplexName}
                        required={true}
                        attrs={{
                            ...register('residentialComplexName', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        size={'xl'}
                    />
                    <InputForm
                        formKey={'subjectRf'}
                        label={'Субъект РФ'}
                        register={register}
                        error={errors.subjectRf}
                        required={true}
                        attrs={{
                            ...register('subjectRf', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                    />
                    <InputForm
                        formKey={'locality'}
                        label={'Населенный пункт'}
                        register={register}
                        error={errors.locality}
                        required={true}
                        attrs={{
                            ...register('locality', { required: true }),
                            disabled: isView,
                        }}
                    />
                    <InputForm
                        formKey={'street'}
                        label={'Улица'}
                        register={register}
                        error={errors.street}
                        required={true}
                        attrs={{
                            ...register('street', { required: true }),
                            disabled: isView,
                        }}
                        size={'l'}
                    />
                    <InputForm
                        formKey={'houseNumber'}
                        label={'Номер дома'}
                        register={register}
                        error={errors.houseNumber}
                        required={true}
                        attrs={{
                            ...register('houseNumber', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        size={'s'}
                    />
                    <InputForm
                        formKey={'kadastrNumber'}
                        label={'Кадастровый номер'}
                        register={register}
                        error={errors.kadastrNumber}
                        size={'m'}
                        mask="99:99:9{6,7}:999{*}"
                        attrs={{
                            ...register('kadastrNumber'),
                            disabled: isView,
                        }}
                    />
                    <InputForm
                        formKey={'apartmentNumber'}
                        label={'Номер квартиры'}
                        register={register}
                        required={true}
                        attrs={{
                            ...register('apartmentNumber', {
                                required: true,
                            }),
                            type: 'number',
                            disabled: isView,
                        }}
                        error={errors.apartmentNumber}
                        size={'m'}
                    />
                    <InputForm
                        formKey={'countOfApartmentsOnFloor'}
                        label={'Кол-во квартир на этаже'}
                        register={register}
                        attrs={{
                            type: 'number',
                            ...register('countOfApartmentsOnFloor'),
                            disabled: isView,
                        }}
                        error={errors.countOfApartmentsOnFloor}
                        size={'s'}
                    />
                    <InputForm
                        formKey={'builtYear'}
                        label={'Год постройки'}
                        register={register}
                        required={true}
                        attrs={{
                            ...register('builtYear', {
                                required: true,
                            }),
                            type: 'number',
                            disabled: isView,
                        }}
                        error={errors.builtYear}
                        fieldClassName={'col-start-1'}
                        size={'xs'}
                    />
                    <InputForm
                        formKey={'buildingFloorsCount'}
                        label={'Кол-во этажей'}
                        required={true}
                        register={register}
                        attrs={{
                            type: 'number',
                            ...register('buildingFloorsCount', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        error={errors.buildingFloorsCount}
                        size={'xs'}
                    />
                    <InputForm
                        formKey={'floorNumber'}
                        label={'Этаж'}
                        register={register}
                        required={true}
                        attrs={{
                            type: 'number',
                            ...register('floorNumber', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        error={errors.floorNumber}
                        size={'s'}
                    />
                    <InputForm
                        formKey={'roomsCount'}
                        label={'Кол-во комнат'}
                        register={register}
                        required={true}
                        attrs={{
                            type: 'number',
                            min: 1,
                            max: 99,
                            ...register('roomsCount', {
                                required: true,
                            }),
                            disabled: isView,
                        }}
                        error={errors.roomsCount}
                        size={'s'}
                    />
                    <InputForm
                        formKey={'area'}
                        label={'Общая площадь, кв. м.'}
                        register={register}
                        required={true}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            ...register('area', { required: true }),
                            disabled: isView,
                        }}
                        size={'s'}
                        error={errors.area}
                        fieldClassName={'col-start-1'}
                    />
                    <InputForm
                        formKey={'summerRoomArea'}
                        label={'Площадь летних помещений, кв. м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            ...register('summerRoomArea'),
                            disabled: isView,
                        }}
                        size={'s'}
                        error={errors.summerRoomArea}
                    />
                    <InputForm
                        formKey={'areaWithSummerRooms'}
                        label={'Общая площадь с лет. пом-ями, кв. м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            readOnly: true,
                            ...register('areaWithSummerRooms'),
                            disabled: isView,
                        }}
                        size={'s'}
                        error={errors.areaWithSummerRooms}
                    />
                    <InputForm
                        formKey={'livingArea'}
                        label={'Жилая площадь, кв. м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            ...register('livingArea'),
                            disabled: isView,
                        }}
                        size={'s'}
                        fieldClassName={'mt-12'}
                        error={errors.livingArea}
                    />
                    <InputForm
                        formKey={'kitchenArea'}
                        label={'Площадь кухни, кв. м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            ...register('kitchenArea'),
                            disabled: isView,
                        }}
                        size={'s'}
                        fieldClassName={'mt-12'}
                        error={errors.kitchenArea}
                    />
                    <SelectForm
                        formKey={'separateKitchen'}
                        label={
                            'Имеет отдельную от других объектов недвижимости кухню'
                        }
                        register={register}
                        options={[
                            { value: 'true', name: 'Да' },
                            { value: 'false', name: 'Нет' },
                        ]}
                        emptyValue={'Выберите ответ'}
                        size={'s'}
                        fieldClassName={'mt-6'}
                        error={errors.separateKitchen}
                        attrs={{
                            ...register('separateKitchen'),
                            disabled: isView,
                        }}
                    />
                    <InputForm
                        formKey={'utilityRoomsArea'}
                        label={'Вспомогательные и подсобные помещения, кв. м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            readOnly: true,
                            ...register('utilityRoomsArea'),
                            disabled: isView,
                        }}
                        size={'s'}
                        error={errors.utilityRoomsArea}
                    />
                    <InputForm
                        formKey={'ceilingHeights'}
                        label={'Высота потолков, кв. м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            ...register('ceilingHeights'),
                            disabled: isView,
                        }}
                        size={'s'}
                        fieldClassName={'mt-6'}
                        error={errors.ceilingHeights}
                    />
                    <SelectForm
                        formKey={'countAndTypeOfRestrooms'}
                        label={'Кол-во и тип санузлов'}
                        register={register}
                        options={[
                            {
                                value: 'Совмещенный',
                                name: 'Совмещенный',
                            },
                            { value: 'Раздельный', name: 'Раздельный' },
                            {
                                value: 'Два сан.узла',
                                name: 'Два сан.узла',
                            },
                            {
                                value: 'Несколько сан.узлов',
                                name: 'Несколько сан.узлов',
                            },
                            { value: 'Другое', name: 'Другое' },
                        ]}
                        emptyValue={'Выберите ответ'}
                        size={'m'}
                        fieldClassName={'col-start-1 sm:mt-12'}
                        error={errors.countAndTypeOfRestrooms}
                        attrs={{
                            ...register('countAndTypeOfRestrooms'),
                            disabled: isView,
                        }}
                    />
                    <SelectForm
                        formKey={'separateRestroom'}
                        label={
                            'Имеет отдельный от других объектов недвижимости санузел (ванная комната и туалет)'
                        }
                        register={register}
                        options={[
                            { value: 'true', name: 'Да' },
                            { value: 'false', name: 'Нет' },
                        ]}
                        emptyValue={'Выберите ответ'}
                        size={'m'}
                        fieldClassName={'mt-6'}
                        error={errors.separateRestroom}
                        attrs={{
                            ...register('separateRestroom'),
                            disabled: isView,
                        }}
                    />
                    <SelectForm
                        formKey={'summerRoomsType'}
                        label={'Наличие балкона/лоджии'}
                        register={register}
                        options={Object.entries(SummerRoomsTypeLabels).map(
                            ([value, name]) => ({ name, value }),
                        )}
                        error={errors.summerRoomsType}
                        emptyValue={'Выберите ответ'}
                        size={'m'}
                        attrs={{
                            ...register('summerRoomsType'),
                            disabled: isView,
                        }}
                    />
                    <SelectForm
                        formKey={'layoutType'}
                        label={'Тип планировки'}
                        register={register}
                        options={Object.entries(LayoutTypeLabels).map(
                            ([value, name]) => ({ name, value }),
                        )}
                        emptyValue={'Выберите ответ'}
                        size={'m'}
                        error={errors.layoutType}
                        attrs={{
                            ...register('layoutType'),
                            disabled: isView,
                        }}
                    />
                    <InputForm
                        formKey={'buildingWeakness'}
                        label={'Процент износа здания (согласно данным БТИ), %'}
                        register={register}
                        attrs={{
                            type: 'number',
                            step: 0.01,
                            defaultValue: 0,
                            ...register('buildingWeakness'),
                            disabled: isView,
                        }}
                        size={'m'}
                        error={errors.buildingWeakness}
                    />
                    <Controller
                        control={control}
                        name="floorPlanBti"
                        // rules={{ required: true }}
                        render={({ field }) => (
                            <DropFile
                                formKey={field.name}
                                ref={field.ref}
                                onUpload={onUploadedFloorPlan}
                                onRemove={onRemovedFloorPlan}
                                label={'Поэтажный план (по документам БТИ)'}
                                isLoading={isLoading}
                                // file={defaultValues?.floorPlanBti}
                                size={'xl'}
                                required={true}
                                error={errors.floorPlanBti}
                            />
                        )}
                    />
                    <ControllerDocumentsGroup<AssessmentObject>
                        control={control}
                        formKey="utilityRoomsArea"
                        label="Правоподтверждающие документы (legal)"
                        typeGroup="legal"
                        groups={documents?.tech || []}
                        isLoading={isLoading}
                        onAfterAddGroup={onAfterAddGroup}
                        onAfterDeletedGroup={onAfterDeletedGroup}
                        size={'xl'}
                        error={errors.utilityRoomsArea}
                    />
                    <ControllerDocumentsGroup<AssessmentObject>
                        control={control}
                        formKey="utilityRoomsArea"
                        label="Правоподтверждающие документы (tech)"
                        typeGroup="tech"
                        groups={documents?.tech || []}
                        isLoading={isLoading}
                        onAfterAddGroup={onAfterAddGroup}
                        onAfterDeletedGroup={onAfterDeletedGroup}
                        size={'xl'}
                        error={errors.utilityRoomsArea}
                    />
                    <InputForm
                        formKey={'district'}
                        label={'Район города'}
                        register={register}
                        size={'m'}
                        fieldClassName={'col-start-1'}
                        error={errors.district}
                        attrs={{ ...register('district'), disabled: isView }}
                    />

                    <InputForm
                        formKey={'nearestMetroStation'}
                        label={'Ближайшая станция метро'}
                        register={register}
                        fieldClassName={'!col-start-1'}
                        error={errors.nearestMetroStation}
                        attrs={{
                            ...register('nearestMetroStation'),
                            disabled: isView,
                        }}
                    />
                    <InputForm
                        formKey={'nearestMetroStationDistance'}
                        label={'Расстояние до ближайшей станции метро, м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            ...register('nearestMetroStationDistance'),
                            disabled: isView,
                        }}
                        size={'m'}
                        error={errors.nearestMetroStationDistance}
                    />
                    <InputForm
                        formKey={'groundTransportStop'}
                        label={'Остановка наземного транспорта'}
                        register={register}
                        fieldClassName={'col-start-1'}
                        attrs={{
                            ...register('groundTransportStop'),
                            disabled: isView,
                        }}
                        error={errors.groundTransportStop}
                    />
                    <InputForm
                        formKey={'groundTransportStopDistance'}
                        label={'Расстояние до остановки, м'}
                        register={register}
                        attrs={{
                            type: 'number',
                            ...register('groundTransportStopDistance'),
                            disabled: isView,
                        }}
                        size={'m'}
                        error={errors.groundTransportStopDistance}
                    />
                </AddPropIsLoading>
            </div>
        </Form>
    );
};
