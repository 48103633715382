import { SelectionRead } from '@ocenkatech/common/api';
import { DataItem, DataList } from '@ocenkatech/common/ui/DataList';
import { FC, useMemo } from 'react';

interface SelectionListProps extends SelectionRead {}

export const SelectionList: FC<SelectionListProps> = ({
    correctedAnalogs,
    price,
    pricePerMeter,
    analogs,
    distance,
    ratio,
}) => {
    const pricePerMeterRange = useMemo(() => {
        const prices =
            correctedAnalogs?.map((a) => a?.correctedPricePerMeter ?? 0) ?? [];
        return `${Math.min(...prices)} - ${Math.max(...prices)}`;
    }, [correctedAnalogs]);

    const orderInfo: DataItem[] = [
        { name: 'Рыночная стоимость', value: price },
        {
            name: 'Средневзвешенная стоимость 1 кв. м.',
            value: pricePerMeter,
        },
        { name: 'Всего найдено аналогов', value: analogs?.length },
        { name: 'Радиус поиска аналогов, м.', value: distance },
        {
            name: 'Аналогов использовано в отчете',
            value: correctedAnalogs?.length,
        },
        {
            name: 'Отношение самого дорогого к самому дешевому аналогу',
            value: ratio,
        },
        {
            name: 'Диапазон стоимости кв. метра аналогов, руб:',
            value: pricePerMeterRange,
        },
    ];
    return <DataList items={orderInfo} />;
};
