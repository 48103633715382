import { FC, PropsWithChildren } from 'react';
import { Outlet, RouteProps } from 'react-router-dom';

export const InnerLayout: FC<PropsWithChildren<RouteProps>> = ({
    children,
}) => {
    return (
        <>
            <main className="py-10 lg:pl-72">
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="overflow-hidden rounded-lg border-b border-gray-200 shadow">
                        <Outlet />
                        {children}
                    </div>
                </div>
            </main>
        </>
    );
};
