import { useSaveUrlParams } from '@ocenkatech/common/lib';
import { HeadingTabs } from '@ocenkatech/common/ui/Heading';
import { Pagination } from '@ocenkatech/common/ui/Pagination';
import { Table } from '@ocenkatech/common/ui/Table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

const pageSize = 10;

export const MassSelectionTable = () => {
    const [page, setPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState('all');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [{ name: 'page', value: page, setValue: setPage }],
        [page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });
    // const filterTabs = Object.entries().map(
    //     ([status, name]) => ({
    //         name,
    //         value: status,
    //     }),
    // );

    // if (error) {
    // return (
    //     <ErrorMessage
    //         message={handleError(error)}
    //         className="bg-white py-8"
    //         />
    //     );
    // }
    return (
        <>
            <div className="bg-white pb-6 pt-10">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <HeadingTabs
                        namePage="Массовые подборы"
                        // tabs={filterTabs}
                        currentTab={filterStatus}
                        onChangeTab={setFilterStatus}
                    />
                </div>
            </div>
            <div className="overflow-x-auto">
                <Table
                    rows={[]}
                    columns={[]}
                    columnsName={[
                        '#',
                        'Дата запуска',
                        'Кол-во объектов',
                        'Дата завершения расчёта',
                    ]}
                    onClick={() => {
                        // navigate(`/selection/mass-selection/${el.id}`);
                    }}
                />
            </div>
            <Pagination
                // isLoading={isLoading}
                onPageChange={setPage}
                // totalItems={totalsInspections.current || inspections?.count}
                currentPage={page}
                itemsPerPage={pageSize}
            />
        </>
    );
};
