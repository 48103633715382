// @ts-nocheck
import React, { useCallback, useEffect, useRef } from 'react';

export const TestSign = () => {
    const filesRef = useRef();

    useEffect(() => {
        new Promise((resolve, reject) => {
            cadesplugin.async_spawn(
                async () => {
                    var oStore =
                        await cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                    await oStore.Open(
                        cadesplugin.CADESCOM_CONTAINER_STORE,
                        cadesplugin.CAPICOM_MY_STORE,
                        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                    );

                    var oCertificates = await oStore.Certificates;
                    var count = await oCertificates.Count;
                    for (var i = 1; i <= count; i++) {
                        await oCertificates.Item(i);
                    }
                },
                resolve,
                reject,
            );
        });

        return () => {};
    }, []);

    const signFile = function SignCreate(dataInBase64) {
        return new Promise(function (resolve, reject) {
            cadesplugin.async_spawn(
                function* (args) {
                    var oStore =
                        yield cadesplugin.CreateObjectAsync('CAdESCOM.Store');
                    yield oStore.Open(
                        cadesplugin.CADESCOM_CONTAINER_STORE,
                        cadesplugin.CAPICOM_MY_STORE,
                        cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
                    );

                    var oStoreCerts = yield oStore.Certificates;
                    var oCertificate = yield oStoreCerts.Item(1);
                    var oSigner =
                        yield cadesplugin.CreateObjectAsync(
                            'CAdESCOM.CPSigner',
                        );
                    yield oSigner.propset_Certificate(oCertificate);
                    yield oSigner.propset_CheckCertificate(true);

                    var oSignedData = yield cadesplugin.CreateObjectAsync(
                        'CAdESCOM.CadesSignedData',
                    );
                    // Значение свойства ContentEncoding должно быть задано
                    // до заполнения свойства Content
                    yield oSignedData.propset_ContentEncoding(
                        cadesplugin.CADESCOM_BASE64_TO_BINARY,
                    );
                    yield oSignedData.propset_Content(dataInBase64);

                    var sSignedMessage = '';
                    try {
                        sSignedMessage = yield oSignedData.SignCades(
                            oSigner,
                            cadesplugin.CADESCOM_CADES_BES,
                            true,
                        );
                    } catch (err) {
                        const e = cadesplugin.getLastError(err);
                        alert('Failed to create signature. Error: ' + e);
                        return args[1](e);
                    }

                    yield oStore.Close();
                    return args[0](sSignedMessage);
                },
                resolve,
                reject,
            );
        });
    };

    const sign = useCallback(() => {
        const fr = new FileReader();
        fr.readAsDataURL(filesRef.current.files[0]);

        fr.onload = async (e) => {
            var header = ';base64,';
            var sFileData = e.target.result;
            var sBase64Data = sFileData.substr(
                sFileData.indexOf(header) + header.length,
            );

            const fetchData = await signFile(sBase64Data);

            const blob = new Blob([fetchData]);
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'sign.sig';
            a.click();
        };
    }, []);

    return (
        <>
            <input type="file" ref={filesRef} />
            <button onClick={sign}>Подписать</button>
        </>
    );
};
