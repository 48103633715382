//@ts-nocheck
import {
    Inspection,
    useApiV1InspectionsCreateMutation,
    useApiV1InspectionsPartialUpdateMutation,
} from '@ocenkatech/common/api';
import { getErrorMessage, removeEmptyValue } from '@ocenkatech/common/lib';
// import { PartialBy } from '@ocenkatech/common/types';
import { Button } from '@ocenkatech/common/ui/Button';
import { Card } from '@ocenkatech/common/ui/Card';
import { DataItem, DataList } from '@ocenkatech/common/ui/DataList';
import { DialogApp, DialogSize } from '@ocenkatech/common/ui/DialogApp';
import { format } from 'date-fns';
import { FC, useCallback, useState, useId } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { InspectionForm } from './InspectionForm';

interface InspectionCardProps {
    className?: string;
    isLoading?: boolean;
    defaultValues?: Inspection;
    // defaultValues?: PartialBy<InspectionRead, 'photoGroups'>;
}

export const InspectionCard: FC<InspectionCardProps> = ({
    className = '',
    isLoading = false,
    defaultValues,
}) => {
    const [isEdit, setIsEdit] = useState(false);
    const formId = useId();

    const [
        create,
        { data: createdInspection, error: errorCreate, isLoading: isCreating },
    ] = useApiV1InspectionsCreateMutation();
    const [
        update,
        { data: updatedInspection, error: errorUpdate, isLoading: isUpdating },
    ] = useApiV1InspectionsPartialUpdateMutation();

    const onShowCard = useCallback(() => {
        setIsEdit(true);
    }, []);

    const onSave = useCallback<SubmitHandler<Inspection>>(
        async (values) => {
            try {
                const inspectionId = defaultValues?.id || createdInspection?.id;

                const preparedValues = removeEmptyValue(values);
                if (inspectionId) {
                    await update({
                        id: inspectionId,
                        patchedInspection: preparedValues,
                    }).unwrap();
                } else {
                    await create({
                        inspection: {
                            ...values,
                            date: format(new Date(), 'yyyy-MM-dd'),
                        },
                    }).unwrap();
                }
                alert('Осмотр сохранен');
            } catch (error) {
                alert(getErrorMessage(error, 'Ошибка при сохранении'));
            }
        },
        [create, createdInspection?.id, defaultValues?.id, update],
    );

    const inspectionInfo: DataItem[] = [
        {
            name: 'Состояние подъезда',
            value:
                createdInspection?.entranceCondition ||
                updatedInspection?.entranceCondition ||
                defaultValues?.entranceCondition,
        },
        {
            name: 'Состояние крыши',
            value:
                createdInspection?.roofCondition ||
                updatedInspection?.roofCondition ||
                defaultValues?.roofCondition,
        },
    ];
    return (
        <>
            <Card onClick={onShowCard} className={className}>
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                    Осмотр
                </h3>
                <div className="pt-6">
                    <DataList items={inspectionInfo} isLoading={isLoading} />
                </div>
            </Card>
            <DialogApp
                open={isEdit}
                size={DialogSize.LG}
                setOpen={setIsEdit}
                title="Редактировать осмотра"
                actionSuccess={
                    <Button
                        disabled={isLoading || isCreating || isUpdating}
                        form={formId}
                        type="submit">
                        Сохранить
                    </Button>
                }>
                <InspectionForm
                    formId={formId}
                    onSave={onSave}
                    defaultValues={
                        createdInspection || updatedInspection || defaultValues
                    }
                    error={errorCreate || errorUpdate}
                    isLoading={isLoading}
                    isProgress={isUpdating || isCreating}
                />
            </DialogApp>
        </>
    );
};
