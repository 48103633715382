import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import { logo } from '@ocenkatech/common/assets';
import { classnames } from '@ocenkatech/common/lib';
import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import {
    FC,
    ForwardRefExoticComponent,
    Fragment,
    PropsWithoutRef,
    RefAttributes,
    SVGProps,
} from 'react';

type Children = { name: string; href: string };

export type ItemMenu = {
    name: string;
    href: string;
    icon?: ForwardRefExoticComponent<
        PropsWithoutRef<SVGProps<SVGSVGElement>> & {
            title?: string;
            titleId?: string;
        } & RefAttributes<SVGSVGElement>
    >;
    children?: Children[];
};

interface SideBarProps {
    menu: ItemMenu[];
    userMenu?: Omit<ItemMenu, 'children'>[];
}

export const SideBar: FC<SideBarProps> = ({ menu, userMenu }) => {
    return (
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
                <LinkApp to="/">
                    <img className="h-8 w-8" src={logo} alt="Ocenka" />
                </LinkApp>
            </div>
            <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul className="-mx-2 space-y-1">
                            {menu.map((item) => (
                                <li key={item.name}>
                                    {!('children' in item) ? (
                                        <LinkApp
                                            activeClass="bg-gray-50"
                                            baseClass="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                            to={item.href}>
                                            {item.icon && (
                                                <item.icon
                                                    aria-hidden="true"
                                                    className="h-6 w-6 shrink-0 text-gray-400"
                                                />
                                            )}
                                            {item.name}
                                        </LinkApp>
                                    ) : (
                                        <Disclosure as="div">
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button
                                                        className={classnames(
                                                            open
                                                                ? 'bg-gray-50'
                                                                : 'hover:bg-gray-50',
                                                            'group flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700',
                                                        )}>
                                                        {item.icon && (
                                                            <item.icon
                                                                aria-hidden="true"
                                                                className="h-6 w-6 shrink-0 text-gray-400"
                                                            />
                                                        )}
                                                        {item.name}
                                                        <ChevronRightIcon
                                                            aria-hidden="true"
                                                            className="ml-auto h-5 w-5 shrink-0 text-gray-400 group-data-[open]:rotate-90 group-data-[open]:text-gray-500"
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel
                                                        as="ul"
                                                        className="mt-1 px-2">
                                                        {item.children?.map(
                                                            (subItem) => (
                                                                <li
                                                                    key={
                                                                        subItem.name
                                                                    }>
                                                                    <LinkApp
                                                                        activeClass="bg-gray-50"
                                                                        baseClass="block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-gray-700 hover:bg-gray-50"
                                                                        to={
                                                                            subItem.href
                                                                        }>
                                                                        {
                                                                            subItem.name
                                                                        }
                                                                    </LinkApp>
                                                                </li>
                                                            ),
                                                        )}
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </li>
                    {userMenu && (
                        <li className="-mx-6 mt-auto">
                            <Menu as="div" className="relative">
                                <Menu.Button className="flex w-full items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                                    <span className="sr-only">
                                        Открыть пользовательское меню
                                    </span>
                                    <UserCircleIcon
                                        className="h-8 w-8 rounded-full text-gray-400"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="absolute bottom-full right-0 z-10 mb-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userMenu.map((item) => (
                                            <Menu.Item key={item.name}>
                                                {() => (
                                                    <LinkApp
                                                        to={item.href}
                                                        baseClass="block px-4 py-2 text-sm text-gray-700"
                                                        activeClass="bg-gray-100">
                                                        {item.name}
                                                    </LinkApp>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
};
