import { FC, PropsWithChildren } from 'react';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className="white mx-auto max-w-7xl bg-white px-4 py-10 sm:px-6 sm:py-8 lg:px-8">
            <div className="lg:col-span-3 lg:row-span-2 lg:row-end-2">
                <div className="grid grid-cols-6 gap-4">{children}</div>
            </div>
        </div>
    );
};
