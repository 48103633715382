import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { classnames, useDebounce } from '@ocenkatech/common/lib';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchAddressQuery } from 'entities/buildings';
import { Suggest } from 'entities/buildings';

type AddressAutocompleteProps = {
    value?: Suggest;
    onChangeFiasData(fiasData: Suggest): void;
    minFiasLevel?: number;
    message?: string;
};
export const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
    onChangeFiasData,
    value,
    minFiasLevel = 8,
    message = 'Выберите дом или квартиру',
}) => {
    const [selected, setSelected] = useState<Suggest | null>(null);
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 250);
    const { data, isLoading, isFetching } = useSearchAddressQuery(
        debouncedQuery,
        {
            skip: debouncedQuery === '',
        },
    );

    const onSelected = useCallback(
        (suggest: Suggest) => {
            setSelected(suggest);
            if (suggest && suggest.data.fiasLevel >= minFiasLevel.toString()) {
                onChangeFiasData({
                    ...suggest,
                });
            }
        },
        [minFiasLevel, onChangeFiasData],
    );

    useEffect(() => {
        if (value) {
            setSelected(value);
            setQuery(value.value);
        }
    }, [onSelected, value]);

    return (
        <div className="col-span-full">
            <Combobox as="div" value={selected} onChange={onSelected}>
                <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    Адрес
                </Combobox.Label>
                <div className="relative mt-2">
                    <Combobox.Input
                        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        displayValue={(suggest: Suggest) => suggest?.value}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    {isLoading || isFetching ? (
                        <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2">
                            <ArrowPathIcon className="h-5 w-5 animate-spin text-gray-400" />
                        </div>
                    ) : (
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </Combobox.Button>
                    )}

                    {!!data?.length && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {data?.map((suggest) => (
                                <Combobox.Option
                                    key={suggest.value}
                                    value={suggest}
                                    className={({ active }) =>
                                        classnames(
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                            active
                                                ? 'bg-indigo-600 text-white'
                                                : 'text-gray-900',
                                        )
                                    }>
                                    {({ active, selected }) => (
                                        <>
                                            <span
                                                className={classnames(
                                                    'block truncate',
                                                    {
                                                        'font-semibold':
                                                            selected,
                                                    },
                                                )}>
                                                {suggest.value}
                                            </span>

                                            {selected && (
                                                <span
                                                    className={classnames(
                                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        active
                                                            ? 'text-white'
                                                            : 'text-indigo-600',
                                                    )}>
                                                    <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            )}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            </Combobox>
            {selected &&
                selected?.data.fiasLevel! < minFiasLevel.toString() && (
                    <p className="mt-2 text-sm text-red-600">{message}</p>
                )}
        </div>
    );
};
