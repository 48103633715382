import { CheckCircleIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import {
    useApiV1AppraisersEvaluatingCompaniesListQuery,
    useApiV1AppraisersListQuery,
} from '@ocenkatech/common/api';
import { AddPropIsLoading } from '@ocenkatech/common/ui/AddPropIsLoading';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { Card } from '@ocenkatech/common/ui/Card';
import { DataItem, DataList } from '@ocenkatech/common/ui/DataList';
import { isNil } from 'lodash';
import { FC, useState } from 'react';
import { AppraiserEdit, Field } from './AppraiserEdit';

interface AppraiserCardProps {
    evaluator?: number | null;
    appraiser?: number | null;
    onChange?: (arg: Record<string, number | null>) => Promise<void>;
    isLoading?: boolean;
    isView?: boolean;
    className?: string;
}

export const AppraiserCard: FC<AppraiserCardProps> = ({
    evaluator,
    appraiser,
    onChange,
    className = '',
    isLoading,
    isView,
}) => {
    const [isEdit, setIsEdit] = useState(false);

    const { data: evaluatingComps, isLoading: isLoadingEvaluatingComp } =
        useApiV1AppraisersEvaluatingCompaniesListQuery();
    const { data: appraisers, isLoading: isLoadingAppraisers } =
        useApiV1AppraisersListQuery({
            evaluatingCompany: !isNil(evaluator) ? evaluator : undefined,
        });

    const currentEvaluationsComp = evaluatingComps?.find(
        (e) => e.id === evaluator,
    );

    const appraiserInfo: DataItem[] = [
        {
            name: 'Оценочная компания',
            value: currentEvaluationsComp
                ? `${currentEvaluationsComp?.name} (${currentEvaluationsComp?.inn})`
                : '',
        },
        {
            name: 'Оценщик',
            value: appraisers?.find((a) => a.id === appraiser)?.name,
        },
    ];

    const fieldsAppraiser: Field[] = [
        {
            label: 'Оценочная компания',
            name: 'evaluator',
            options:
                evaluatingComps?.map((company) => ({
                    name: `${company.name} (${company.inn})`,
                    value: String(company.id),
                })) ?? [],
            value: evaluator,
        },
        {
            label: 'Оценщик',
            name: 'assignTo',
            options:
                appraisers?.map((appraiser) => ({
                    name: appraiser.name,
                    value: String(appraiser.id),
                })) ?? [],
            value: appraiser,
        },
    ];

    return (
        <Card className={className}>
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div className="ml-4 mt-2">
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Назначенная ОК и оценщик
                    </h3>
                </div>
                {!isView && (
                    <div className="ml-4 mt-2 flex-shrink-0">
                        <Button
                            round
                            theme={ButtonTheme.GREY}
                            onClick={() => setIsEdit((prev) => !prev)}>
                            {isEdit ? (
                                <CheckCircleIcon className="size-5" />
                            ) : (
                                <PencilSquareIcon className="size-5" />
                            )}
                        </Button>
                    </div>
                )}
            </div>
            <AddPropIsLoading
                isLoading={
                    isLoading || isLoadingEvaluatingComp || isLoadingAppraisers
                }>
                {isEdit ? (
                    <AppraiserEdit
                        fields={fieldsAppraiser}
                        onChange={onChange}
                    />
                ) : (
                    <DataList items={appraiserInfo} />
                )}
            </AddPropIsLoading>
        </Card>
    );
};
