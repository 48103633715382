'use client';
var SummerRooms;
(function (SummerRooms) {
    SummerRooms[SummerRooms["UNKNOWN"] = -1] = "UNKNOWN";
    SummerRooms[SummerRooms["NO"] = 0] = "NO";
    SummerRooms[SummerRooms["BALCONY"] = 1] = "BALCONY";
    SummerRooms[SummerRooms["LOGGIA"] = 2] = "LOGGIA";
})(SummerRooms || (SummerRooms = {}));
var RenovationTypes;
(function (RenovationTypes) {
    RenovationTypes[RenovationTypes["UNKNOWN"] = -1] = "UNKNOWN";
    RenovationTypes[RenovationTypes["NO"] = 0] = "NO";
    RenovationTypes[RenovationTypes["COSMETIC"] = 1] = "COSMETIC";
    RenovationTypes[RenovationTypes["EURO"] = 2] = "EURO";
    RenovationTypes[RenovationTypes["DESIGN"] = 3] = "DESIGN";
    RenovationTypes[RenovationTypes["PRECLEAN"] = 4] = "PRECLEAN";
    RenovationTypes[RenovationTypes["ROUGH"] = 5] = "ROUGH";
})(RenovationTypes || (RenovationTypes = {}));
var HouseType;
(function (HouseType) {
    HouseType[HouseType["UNKNOWN"] = -1] = "UNKNOWN";
    HouseType[HouseType["NEW"] = 1] = "NEW";
    HouseType[HouseType["RESALE"] = 2] = "RESALE";
})(HouseType || (HouseType = {}));
var WindowsView;
(function (WindowsView) {
    WindowsView[WindowsView["UNKNOWN"] = -1] = "UNKNOWN";
    WindowsView[WindowsView["YARD"] = 0] = "YARD";
    WindowsView[WindowsView["STREET"] = 1] = "STREET";
})(WindowsView || (WindowsView = {}));
var WallTypes;
(function (WallTypes) {
    WallTypes["UNKNOWN"] = "unknown";
    WallTypes["BRICK"] = "brick";
    WallTypes["PANEL"] = "panel";
    WallTypes["BLOCK"] = "block";
    WallTypes["MONOLITH"] = "monolith";
    WallTypes["MONOLITH_BRICK"] = "monolith_brick";
    WallTypes["WOODEN"] = "wooden";
})(WallTypes || (WallTypes = {}));

export { HouseType, RenovationTypes, SummerRooms, WallTypes, WindowsView };
