import { OrderCreate } from '@ocenkatech/common/api';
import { SectionProps } from 'shared/lib/generateForm';

export const citiesForm: SectionProps<OrderCreate>[] = [
    {
        title: 'Основные',
        subtitle: 'Основные параметры',
        fields: [
            {
                type: 'string',
                props: { formKey: 'address', label: 'test' },
            },
        ],
    },
    {
        title: 'Основные',
        subtitle: 'Основные параметры',
        fields: [
            {
                type: 'string',
                props: { formKey: 'address', label: 'test' },
            },
        ],
    },
];
