import { HeadingActions } from '@ocenkatech/common/ui/Heading';
import React from 'react';
import { CreateSelectionForm } from './CreateSelectionForm';

export const NewSelection: React.FC = () => {
    return (
        <>
            <HeadingActions title="Новый подбор" fullWidth />
            <div className="bg-white px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                <CreateSelectionForm />
            </div>
        </>
    );
};
