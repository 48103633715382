import { classnames, getErrorMessage } from '@ocenkatech/common/lib';
import { Identifier } from 'dnd-core';
import { FC, memo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../module/ItemTypes';
import { Document } from '../module/types';
import { DocumentItem, DocumentItemProps } from './DocumentItem';

interface DnDDocumentItemProps extends DocumentItemProps {
    movePositionDocument: (dragPosition: number, hoverPosition: number) => void;
    updatePosition: (
        id: number,
        position: number,
    ) => Promise<Document | undefined>;
}

export const DnDDocumentItem: FC<DnDDocumentItemProps> = memo((props) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<
        Document & { index: number; prevDocId: number },
        Promise<void>,
        { handlerId: Identifier | null }
    >({
        accept: ItemTypes.DOCUMENT,
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover: (item, monitor) => {
            const dragPosition = Number(item.index);
            const hoverPosition = props.index;

            if (dragPosition === hoverPosition) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            if (!hoverBoundingRect) {
                return;
            }
            const clientOffset = monitor.getClientOffset();

            const hoverMiddleX =
                (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const hoverClientX = Number(
                clientOffset && hoverBoundingRect.right - clientOffset.x,
            );

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverClientY = Number(
                clientOffset && hoverBoundingRect.bottom - clientOffset.y,
            );

            if (
                dragPosition < hoverPosition &&
                hoverClientX < hoverMiddleX &&
                hoverClientY < hoverMiddleY
            ) {
                return;
            }

            if (
                dragPosition > hoverPosition &&
                hoverClientX > hoverMiddleX &&
                hoverClientY > hoverMiddleY
            ) {
                return;
            }

            props.movePositionDocument(dragPosition, hoverPosition);
            item.index = hoverPosition;
            item.prevDocId = props.doc.id;
        },
        drop: async (item) => {
            try {
                await props.updatePosition(item.id, item.index);
                await props.updatePosition(item.prevDocId, item.position);
            } catch (error) {
                alert(
                    getErrorMessage(
                        error,
                        'Произошла ошибка при перемещении документа',
                    ),
                );
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.DOCUMENT,
        item: () => ({ ...props.doc, index: props.index }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            className={classnames({ 'opacity-30': isDragging }, 'relative')}>
            <DocumentItem {...props} />
        </div>
    );
});
