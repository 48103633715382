import { TOKEN_KEY } from '@ocenkatech/common/const';
import { removeInStorage } from '@ocenkatech/common/lib';
import { tokenActions } from '@ocenkatech/common/models';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';

export const LogoutPage: React.FC = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        removeInStorage(TOKEN_KEY);
        dispatch(tokenActions.removeToken());
    }, [dispatch]);
    return <Navigate to={'/'} />;
};
