import React from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import { TestSign } from 'TestSign';
import { Application } from 'pages/dashboard/applications';
import { ApplicationsTable } from 'pages/dashboard/applications';
import { CitiesTable } from 'pages/dashboard/cities-regions';
import { Import } from 'pages/dashboard/import';
import { InspectionTable } from 'pages/dashboard/inspection';
import { InspectionView } from 'pages/dashboard/inspection';
import { InspectionEdit } from 'pages/dashboard/inspection';
import { Report, ReportsTable } from 'pages/dashboard/report';
import {
    MassSelection,
    MassSelectionTable,
    SelectionsTable,
} from 'pages/dashboard/selection';
import { NewSelection } from 'pages/dashboard/selection';
import { Selection } from 'pages/dashboard/selection';
import { LoginPage } from 'pages/login';
import { LogoutPage } from 'pages/logout';
import { BaseLayout } from './layouts/BaseLayout';
import { InnerLayout } from './layouts/InnerLayout';
import { ProtectedRoute } from './providers/router';

export const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route
                    path={'/'}
                    element={
                        <ProtectedRoute>
                            <BaseLayout />
                        </ProtectedRoute>
                    }>
                    <Route path="import" element={<Import />} />
                    <Route path="applications" element={<InnerLayout />}>
                        <Route index element={<ApplicationsTable />} />
                        <Route path="new" element={<Application />} />
                        <Route path=":id" element={<Application isView />} />
                        <Route path=":id/edit" element={<Application />} />
                    </Route>
                    <Route path="selection" element={<InnerLayout />}>
                        <Route index element={<SelectionsTable />} />
                        <Route path="new" element={<NewSelection />} />
                        <Route path=":id" element={<Selection />} />
                        <Route path="mass-selection">
                            <Route index element={<MassSelectionTable />} />
                            <Route path=":id" element={<MassSelection />} />
                        </Route>
                    </Route>
                    <Route path="reports" element={<InnerLayout />}>
                        <Route index element={<ReportsTable />} />
                        <Route path=":id" element={<Report />} />
                    </Route>
                    <Route path="inspections" element={<InnerLayout />}>
                        <Route index element={<InspectionTable />} />
                        <Route path=":id/edit" element={<InspectionEdit />} />
                        <Route path=":id/view" element={<InspectionView />} />
                    </Route>
                    <Route
                        path="/cities"
                        element={
                            <InnerLayout>
                                <CitiesTable />
                            </InnerLayout>
                        }
                    />

                    <Route
                        path="/"
                        element={<Navigate to={'/applications'} />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to={'/applications'} />}
                    />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/test" element={<TestSign />} />
                <Route path="*" element={<Navigate to={'/'} />} />
            </Routes>
        </Router>
    );
};
