import { HeadingActions } from '@ocenkatech/common/ui/Heading';
import { Pagination } from '@ocenkatech/common/ui/Pagination';
import { Table } from '@ocenkatech/common/ui/Table';
import { useState } from 'react';
import { useParams } from 'react-router';
import { InfoMassSelectionCard } from 'widgets/infoMassSelectionCard';
import { UploadMassSelectionCard } from 'widgets/uploadMassSelectionCard';

const pageSize = 10;

export const MassSelection = () => {
    const [page, setPage] = useState(1);
    const { id } = useParams();

    return (
        <>
            <HeadingActions title={`Массовый подбор №${id}`} />
            <div className="white mx-auto max-w-7xl bg-white px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                <div className="grid grid-cols-3 gap-4">
                    <UploadMassSelectionCard />
                    <InfoMassSelectionCard className="col-span-2" />
                </div>
                <div className="mt-8 overflow-x-auto">
                    <Table
                        columns={[]}
                        columnsName={[
                            '#',
                            'Адрес',
                            'Площадь',
                            'Стоимость',
                            'Статус',
                        ]}
                        rows={[]}
                    />
                </div>
                <Pagination
                    // isLoading={isLoading}
                    onPageChange={setPage}
                    // totalItems={totalsInspections.current || inspections?.count}
                    currentPage={page}
                    itemsPerPage={pageSize}
                />
            </div>
        </>
    );
};
