//@ts-nocheck
import {
    ArchiveBoxArrowDownIcon,
    PencilIcon,
} from '@heroicons/react/24/outline';
import {
    OrderCreate,
    PatchedOrderCreate,
    useApiV1CrmOrdersCreateMutation,
    useApiV1CrmOrdersPartialUpdateMutation,
    useLazyApiV1CrmOrdersRetrieveQuery,
    useApiV1ResidentialDevelopersListQuery,
    useApiV1BanksListQuery,
    useApiV1CitiesListQuery,
    Order,
    DocumentsGroupRead,
} from '@ocenkatech/common/api';
import { RenovationsLabels } from '@ocenkatech/common/const';
import {
    classnames,
    getErrorMessage,
    handleError,
    removeEmptyValue,
    useHandleErrors,
} from '@ocenkatech/common/lib';
import { AddPropIsLoading } from '@ocenkatech/common/ui/AddPropIsLoading';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { Checkbox } from '@ocenkatech/common/ui/Checkbox';
import { ControlCombobox, Option } from '@ocenkatech/common/ui/Combobox';
import { HeadingActions } from '@ocenkatech/common/ui/Heading';
import { InputForm } from '@ocenkatech/common/ui/Input';
import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { isNil, mapValues } from 'lodash';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm, FieldError } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ActivityFeedCard } from 'widgets/activityFeedCard';
import { AppraiserCard } from 'widgets/appraiserCard';
import { AssessmentObjectCard } from 'widgets/assessmentObjectCard';
import { CustomerCard } from 'widgets/customerCard';
import { InspectionCard } from 'widgets/inspectionCard';
import { UploadReportCard } from 'widgets/uploadReportCard';
import { ControllerDocumentsGroup } from 'features/documents';
import { LabelOrderStatus } from 'entities/appraisers';
import { Layout } from './Layout';

interface ApplicationProps {
    isView?: boolean;
}

export const Application: FC<ApplicationProps> = ({ isView }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [fetchOrder, { data: order, isLoading }] =
        useLazyApiV1CrmOrdersRetrieveQuery();

    const {
        handleSubmit,
        getValues,
        setValue,
        register,
        setError,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm<OrderCreate>();
    const [customerId, assessmentObjectId, cityId, evaluatorId, assignToId] =
        watch([
            'customer',
            'assessmentObject',
            'city',
            'evaluator',
            'assignTo',
        ]);

    const { currentData: cities, isLoading: isLoadingCities } =
        useApiV1CitiesListQuery();
    const { currentData: banks, isLoading: isLoadingBanks } =
        useApiV1BanksListQuery();
    const { currentData: developers, isLoading: isLoadingDevelopers } =
        useApiV1ResidentialDevelopersListQuery();
    const [create, { isLoading: isCreating, error: errorCreate }] =
        useApiV1CrmOrdersCreateMutation();
    const [
        update,
        { data: updateData, isLoading: isUpdating, error: errorUpdate },
    ] = useApiV1CrmOrdersPartialUpdateMutation();

    useHandleErrors(errorCreate || errorUpdate, setError);
    useEffect(() => {
        if (id) {
            fetchOrder({ id: +id });
        }
    }, [fetchOrder, id]);
    useEffect(() => {
        if (order) {
            reset({
                ...mapValues<OrderCreate>(order, (v: string) =>
                    isNil(v) ? '' : v,
                ),
                customer: order?.customer?.id,
                assessmentObject: order?.assessmentObject?.id,
            });
        }
    }, [order, reset]);

    const optionsCity = useMemo<Option[]>(
        () =>
            cities
                ?.map((c) => ({
                    name: c.name,
                    value: c.id.toString(),
                }))
                .sort((a, b) => a.name.localeCompare(b.name)) ?? [],
        [cities],
    );

    const optionsBank = useMemo<Option[]>(
        () =>
            banks?.map((b) => ({
                name: b.name,
                value: b.id.toString(),
            })) ?? [],
        [banks],
    );

    const optionsDeveloper = useMemo<Option[]>(
        () =>
            developers?.map((d) => ({
                name: d?.name ?? '',
                value: d.id.toString(),
            })) ?? [],
        [developers],
    );

    const onUpdate = useCallback(
        async (values: PatchedOrderCreate) => {
            try {
                if (order?.id && !isView) {
                    await update({
                        id: order.id,
                        patchedOrderCreate: values,
                    }).unwrap();
                }
            } catch (error: unknown) {
                const _error = error as FetchBaseQueryError;
                throw new Error(handleError(_error));
            }
        },
        [order?.id, isView, update],
    );

    const onSubmit = useCallback<SubmitHandler<Order>>(
        async (values) => {
            try {
                if (isView) return;
                if (order?.id) {
                    await onUpdate(removeEmptyValue(values));
                    alert(`Заказ №${order?.id} сохранен`);
                } else {
                    const newOrder = await create({
                        orderCreate: values,
                    }).unwrap();
                    navigate(`/applications/${newOrder.id}/edit`);
                }
            } catch (error) {
                alert(getErrorMessage(error));
            }
        },
        [create, isView, navigate, onUpdate, order?.id],
    );

    const onClickArchive = useCallback(async () => {
        if (order?.id) {
            await onUpdate({
                status: 'archived',
            });

            navigate('/applications');
        }
    }, [navigate, onUpdate, order?.id]);

    const onAfterAddGroup = useCallback(
        (group: DocumentsGroupRead) => {
            const newDocumentsGroupsId = [
                ...(getValues('documents') ?? []),
                group.id,
            ];
            onUpdate({ documents: newDocumentsGroupsId });
            setValue('documents', newDocumentsGroupsId);
        },
        [getValues, onUpdate, setValue],
    );

    const onAfterDeletedGroup = useCallback(
        (id: number) => {
            const documentsGroupsId = getValues('documents') ?? [];
            const newDocumentsGroupsId =
                documentsGroupsId.filter((gId) => gId !== id) ?? [];
            onUpdate({ documents: newDocumentsGroupsId });
            setValue('documents', newDocumentsGroupsId);
        },
        [getValues, onUpdate, setValue],
    );

    const onUpdateStatus = useCallback(async () => {
        try {
            const values = getValues();
            if (!order?.id) return;
            await update({
                id: order.id,
                patchedOrderCreate: {
                    status: values.status,
                },
            }).unwrap();
        } catch (e) {
            alert(getErrorMessage(e));
        }
    }, [getValues, order?.id, update]);

    const currentOrder = updateData ?? order;

    return (
        <>
            <HeadingActions
                title={id ? `Заявка №${id}` : 'Новая заявка'}
                isLoading={isLoading}
                badge={
                    currentOrder?.status && {
                        title: LabelOrderStatus[currentOrder.status],
                    }
                }>
                {isView && (
                    <>
                        {currentOrder?.status === 'draft' ? (
                            <LinkApp to={`/applications/${id}/edit`}>
                                <Button icon>
                                    <PencilIcon className="h-5 w-5" />
                                    Редактировать
                                </Button>
                            </LinkApp>
                        ) : (
                            currentOrder?.status !== 'archived' && (
                                <Button
                                    icon
                                    loading={isUpdating}
                                    onClick={onClickArchive}
                                    theme={ButtonTheme.SECONDARY}>
                                    <ArchiveBoxArrowDownIcon className="h-5 w-5" />
                                    Архивировать
                                </Button>
                            )
                        )}
                    </>
                )}
            </HeadingActions>
            <Layout>
                <div className="col-span-full grid grid-cols-4 items-end justify-items-start gap-4">
                    <ControlCombobox
                        name="status"
                        label="Статус заявки"
                        options={Object.entries(LabelOrderStatus)
                            .filter(([key]) => !!key)
                            .map(([key, name]) => ({ name, value: key }))}
                        control={control}
                        // attrs={{ ...register('status'), disabled: isView }}
                        isLoading={isLoading || isUpdating}
                        className="w-full"
                        // size="xs"
                    />
                    <Button
                        onClick={onUpdateStatus}
                        loading={isLoading || isUpdating}>
                        Изменить статус
                    </Button>
                </div>
                <AddPropIsLoading isLoading={isLoading}>
                    <ControlCombobox
                        control={control}
                        name="city"
                        label="Город"
                        className="col-span-2"
                        options={optionsCity}
                        rules={{ required: true }}
                        isLoading={isLoadingCities || isLoading}
                        error={errors.city}
                        required
                    />
                    <ControlCombobox
                        control={control}
                        name="bank"
                        label="Банк"
                        className="col-span-2"
                        options={optionsBank}
                        rules={{ required: true }}
                        isLoading={isLoadingBanks || isLoading}
                        error={errors.bank}
                        required
                    />
                    <ControlCombobox
                        control={control}
                        name="developer"
                        label="Застройщик"
                        className="col-span-2"
                        options={optionsDeveloper}
                        isLoading={isLoadingDevelopers || isLoading}
                        error={errors.developer}
                    />
                    <InputForm
                        label="Ваш ЖК"
                        formKey="residentialComplex"
                        register={register}
                        attrs={{
                            ...register('residentialComplex'),
                            disabled: isView,
                        }}
                        error={errors.residentialComplex}
                        size="s"
                    />
                    <InputForm
                        formKey="street"
                        label="Улица"
                        register={register}
                        attrs={{ ...register('street'), disabled: isView }}
                        error={errors.street}
                        size="s"
                    />
                    {order?.type === 'resale'}
                    <div className="col-span-full mt-4 grid grid-cols-6 gap-4 border-y py-4">
                        <h3 className="col-span-full mb-2 text-2xl font-semibold">
                            Вторичка
                        </h3>
                        <AddPropIsLoading isLoading={isLoading}>
                            <InputForm
                                formKey="address"
                                label="Адрес"
                                register={register}
                                attrs={{
                                    ...register('address'),
                                    disabled: isView,
                                }}
                                error={errors.address}
                                size="s"
                            />
                            <InputForm
                                formKey="floor"
                                label="Этаж"
                                register={register}
                                type="number"
                                attrs={{
                                    ...register('floor'),
                                    min: 0,
                                    max: 9999,
                                    disabled: isView,
                                }}
                                error={errors.floor}
                                size="s"
                            />
                            <InputForm
                                formKey="price"
                                label="Цена"
                                register={register}
                                attrs={{
                                    ...register('price'),
                                    disabled: isView,
                                }}
                                error={errors.price}
                                size="s"
                            />
                        </AddPropIsLoading>
                    </div>

                    <InputForm
                        formKey="building"
                        label="Дом/Литера"
                        register={register}
                        attrs={{ ...register('building'), disabled: isView }}
                        error={errors.building}
                        size="s"
                    />
                    <InputForm
                        formKey="apartmentNumber"
                        label="Номер квартиры"
                        register={register}
                        type="number"
                        attrs={{
                            ...register('building'),
                            min: 0,
                            max: 9999,
                            placeholder: 'Укажите номер квартиры',
                            disabled: isView,
                        }}
                        error={errors.apartmentNumber}
                        size="s"
                    />
                    <InputForm
                        formKey="balconyCount"
                        label="Количество балконов"
                        register={register}
                        type="number"
                        attrs={{ ...register('balconyCount') }}
                        error={errors.area}
                        size="s"
                    />
                    <InputForm
                        formKey="area"
                        label="Площадь, м2"
                        register={register}
                        type="number"
                        attrs={{ ...register('area') }}
                        error={errors.area}
                        size="s"
                    />
                    <ControlCombobox
                        control={control}
                        name="repairType"
                        label="Ремонт"
                        className="col-span-2"
                        options={Object.entries(RenovationsLabels).map(
                            ([value, name]) => ({ name, value }),
                        )}
                        disabled={isView}
                        error={errors.repairType}
                        required
                    />
                    <Checkbox
                        formKey="isSuperServiceAvailable"
                        label="Доступен ли супер сервис"
                        register={register}
                        error={errors.isSuperServiceAvailable}
                        disabled={isView}
                        fieldClassName="col-start-1"
                        size="xs"
                    />
                    <Checkbox
                        formKey="isKadastr"
                        label="Кадастр"
                        register={register}
                        error={errors.isKadastr}
                        disabled={isView}
                        size="xs"
                    />
                    <Checkbox
                        formKey="isPartner"
                        label="Партнер"
                        register={register}
                        error={errors.isPartner}
                        disabled={isView}
                        size="xs"
                    />
                    <Checkbox
                        formKey="hasBalcony"
                        label="Есть ли в квартире лоджии/балконы/террасы?"
                        register={register}
                        error={errors.isSuperServiceAvailable}
                        disabled={isView}
                        size="xs"
                    />

                    <ControllerDocumentsGroup<OrderCreate>
                        control={control}
                        formKey="documents"
                        label="Документы"
                        groups={/*order?.documents && */ []}
                        onAfterAddGroup={onAfterAddGroup}
                        onAfterDeletedGroup={onAfterDeletedGroup}
                        size={'xl'}
                        error={errors.documents as FieldError}
                        // rules={{ required: true }}
                    />
                    <CustomerCard
                        defaultValues={order?.customer}
                        onAfterCreate={async (value) => {
                            await onUpdate({ customer: value.id });
                            setValue('customer', value.id);
                        }}
                        className={classnames(
                            {
                                '!border-yellow-500': !customerId && !isLoading,
                            },
                            'col-span-3',
                        )}
                        isView={isView}
                    />
                    <AssessmentObjectCard
                        defaultValues={order?.assessmentObject}
                        onAfterCreate={async (value) => {
                            await onUpdate({ assessmentObject: value.id });
                            setValue('assessmentObject', value.id);
                        }}
                        city={cityId}
                        className={classnames(
                            {
                                '!border-yellow-500':
                                    !assessmentObjectId && !isLoading,
                            },
                            'col-span-3',
                        )}
                        isView={isView}
                    />
                    {order?.id && (
                        <InspectionCard
                            defaultValues={order?.inspection}
                            className="col-span-2"
                        />
                    )}

                    <AppraiserCard
                        evaluator={evaluatorId}
                        appraiser={assignToId}
                        onChange={onUpdate}
                        className="col-span-2"
                        isView={isView}
                    />
                    <ActivityFeedCard className="col-span-2" />
                    <UploadReportCard
                        file={order?.reportFile}
                        isLoading={isLoading}
                        onUpdate={onUpdate}
                        isView={isView}
                    />

                    {!isView && (
                        <div className="col-span-6 mt-4 flex">
                            <Button
                                onClick={() => handleSubmit(onSubmit)()}
                                loading={isLoading || isCreating || isUpdating}
                                disabled={isLoading}>
                                {id ? 'Сохранить' : 'Создать заявку'}
                            </Button>
                        </div>
                    )}
                </AddPropIsLoading>
            </Layout>
        </>
    );
};
