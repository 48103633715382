import {
    CreateSelection,
    useApiV1SelectionsCreateMutation,
    useApiV1CrmAssessmentObjectCreateMutation,
} from '@ocenkatech/common/api';
import { getErrorMessage } from '@ocenkatech/common/lib';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { Tabs } from 'shared/ui/Tabs/Tabs';
import { SelectionSchema } from '../../model/SelectionSchema';
import { CreateResidentialSelectionForm } from './CreateResidentialSelectionForm';
import { SelectionForm } from './SelectionForm';

type CreateSelectionFormProps = {
    onSubmit?: (cs: SelectionSchema) => void;
};
export const CreateSelectionForm: React.FC<CreateSelectionFormProps> = ({
    onSubmit,
}) => {
    const [createNewSelection, { isLoading, error }] =
        useApiV1SelectionsCreateMutation();
    const [createAssessmentObject, { isLoading: isCreatingAO }] =
        useApiV1CrmAssessmentObjectCreateMutation();
    const navigate = useNavigate();

    const createSelection = useCallback(
        async (cs: CreateSelection) => {
            try {
                const assessmentObject = await createAssessmentObject({
                    assessmentObject: cs,
                }).unwrap();

                //@ts-ignore
                const { id } = await createNewSelection({
                    createSelection: { assessmentObject: assessmentObject.id },
                }).unwrap();

                navigate('/selection/' + id);
            } catch (e) {
                alert(getErrorMessage(e, 'Произошла ошибка'));
            }
        },
        [createAssessmentObject, createNewSelection, navigate],
    );

    return (
        <>
            <Tabs
                tabs={['Подбор по ЖК', 'Стандартный']}
                content={[
                    <CreateResidentialSelectionForm
                        onSubmit={onSubmit || createSelection}
                        error={error}
                    />,
                    <SelectionForm
                        onSubmit={onSubmit || createSelection}
                        isLoading={isLoading || isCreatingAO}
                    />,
                ]}
            />
        </>
    );
};
