import { DocumentPlusIcon, PlusIcon } from '@heroicons/react/24/outline';
import {
    StatusFceEnum,
    SelectionListRead,
    useApiV1SelectionsListQuery,
} from '@ocenkatech/common/api';
import { handleError, useSaveUrlParams } from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import { ErrorMessage } from '@ocenkatech/common/ui/ErrorMessage';
import { HeadingTabs } from '@ocenkatech/common/ui/Heading';
import { Pagination } from '@ocenkatech/common/ui/Pagination';
import { Table } from '@ocenkatech/common/ui/Table';
import { useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SelectionStatusLabels } from 'entities/selections';

const pageSize = 10;

export const SelectionsTable = () => {
    const [page, setPage] = useState<number>(1);
    const [filterStatus, setFilterStatus] = useState<StatusFceEnum | ''>('');
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [
            { name: 'status', value: filterStatus, setValue: setFilterStatus },
            { name: 'page', value: page, setValue: setPage },
        ],
        [filterStatus, page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });

    const { currentData, isFetching, isLoading, error } =
        useApiV1SelectionsListQuery({
            page,
            pageSize,
            status: filterStatus as StatusFceEnum,
        });

    const totalsSelections = useRef<number>();
    const filterTabs = useMemo(
        () =>
            Object.entries({
                '': 'Все',
                ...SelectionStatusLabels,
            })
                .map(([status, name]) => ({
                    name,
                    value: status,
                }))
                .sort((a, b) => a.value.localeCompare(b.value)),
        [],
    );

    const preparedSelections = useMemo(
        () =>
            currentData?.results?.map((item: SelectionListRead) => ({
                ...item,
                address: item.assessmentObject,
                statusLabel: SelectionStatusLabels[item.status],
                timeChange: new Date(
                    item.modified || item.created,
                ).toLocaleString(),
            })) ?? [],
        [currentData?.results],
    );

    if (currentData?.count && totalsSelections.current !== currentData?.count) {
        totalsSelections.current = currentData.count;
    }

    if (error) {
        return (
            <ErrorMessage
                message={handleError(error)}
                className="bg-white py-8"
            />
        );
    }

    return (
        <>
            <div className="bg-white pb-6 pt-10">
                <div className="px-4 sm:px-6 lg:px-8">
                    <HeadingTabs
                        namePage="Подбор аналогов"
                        actions={[
                            <Link key={'new'} to={'new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новый подбор
                                </Button>
                            </Link>,
                            <Link key={'mass-selection'} to={'mass-selection'}>
                                <Button>Массовый запуск</Button>
                            </Link>,
                        ]}
                        tabs={filterTabs}
                        currentTab={filterStatus}
                        onChangeTab={setFilterStatus}
                    />
                </div>
            </div>
            {currentData?.count === 0 ? (
                <div className="bg-white px-4 py-12">
                    <div className="text-center">
                        <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Подборов нет
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Начните с создания нового подбора.
                        </p>
                        <div className="mt-6">
                            <Link to={'/inspection/new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новый подбор
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <Table
                            isLoading={isFetching}
                            rows={preparedSelections}
                            columns={[
                                { key: 'id', className: 'w-[1%]' },
                                { key: 'order', className: 'w-[1%]' },
                                { key: 'address' },
                                {
                                    key: 'statusLabel',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                                {
                                    key: 'timeChange',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                            ]}
                            columnsName={[
                                '#',
                                'Заявка',
                                'Адрес',
                                'Статус',
                                'Время изменения',
                            ]}
                            onClick={(el) => navigate(`/selection/${el.id}`)}
                        />
                    </div>
                    <Pagination
                        isLoading={isLoading}
                        onPageChange={setPage}
                        totalItems={
                            totalsSelections.current || currentData?.count
                        }
                        currentPage={page}
                        itemsPerPage={pageSize}
                    />
                </>
            )}
        </>
    );
};
