//@ts-nocheck
import {
    Inspection,
    PhotoGroupRead,
    PhotoRead,
    useApiV1InspectionsPhotoGroupsCreateMutation,
    useApiV1InspectionsPhotoGroupsDestroyMutation,
    useApiV1InspectionsPhotoGroupsPartialUpdateMutation,
    useApiV1InspectionsPhotosCreateMutation,
    useApiV1InspectionsPhotosDestroyMutation,
    useApiV1InspectionsPhotosPartialUpdateMutation,
} from '@ocenkatech/common/api';
import { RenovationsLabels, WindowsViewLabels } from '@ocenkatech/common/const';
import { useHandleErrors } from '@ocenkatech/common/lib';
import {
    PartialBy,
    RenovationTypes,
    WindowsView,
} from '@ocenkatech/common/types';
import { AddPropIsLoading } from '@ocenkatech/common/ui/AddPropIsLoading';
import { ControlCombobox, MultiCombobox } from '@ocenkatech/common/ui/Combobox';
import { Field } from '@ocenkatech/common/ui/Field';
import { Form } from '@ocenkatech/common/ui/Form';
import { InputForm } from '@ocenkatech/common/ui/Input';
import { SelectForm } from '@ocenkatech/common/ui/Select';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FC, FormEvent, useCallback, useMemo } from 'react';
import {
    Controller,
    SubmitHandler,
    UseFormProps,
    useForm,
} from 'react-hook-form';
import { DocumentsGroup } from 'features/documents';
import { Section } from 'shared/ui/Section';

interface InspectionFormProps
    extends Omit<UseFormProps<Inspection>, 'defaultValues'> {
    onSave: SubmitHandler<Inspection>;
    onUpdate?: SubmitHandler<Inspection>;
    formId?: string;
    error?: FetchBaseQueryError | SerializedError;
    defaultValues?: PartialBy<Inspection, 'photoGroups'>;
    isLoading?: boolean;
    isProgress?: boolean;
}

export const InspectionForm: FC<InspectionFormProps> = ({
    onSave,
    onUpdate,
    formId,
    error,
    defaultValues,
    isLoading = false,
    isProgress = false,
}) => {
    const {
        register,
        setError,
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<Inspection>({ values: defaultValues });

    useHandleErrors<Inspection>(error, setError, true);

    const documentsGroup = useMemo(() => {
        const photoGroups = defaultValues?.photoGroups;
        return (
            photoGroups?.map((pg) => ({
                ...pg,
                documents: pg.photos.map((p, index) => ({
                    ...p,
                    group: p.photoGroup,
                    originalName: 'photo',
                    position: index,
                })),
            })) ?? []
        );
    }, [defaultValues?.photoGroups]);

    const onHandleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const actionName = (event.nativeEvent as SubmitEvent).submitter
                ?.dataset.name;

            switch (actionName) {
                case 'update':
                    onUpdate && handleSubmit(onUpdate);
                    break;
                default:
                    handleSubmit(onSave)();
                    break;
            }
        },
        [handleSubmit, onSave, onUpdate],
    );

    const [createPhotoGroup] = useApiV1InspectionsPhotoGroupsCreateMutation();
    const [partialUpdatePhotoGroup] =
        useApiV1InspectionsPhotoGroupsPartialUpdateMutation();
    const [destroyPhotoGroup] = useApiV1InspectionsPhotoGroupsDestroyMutation();

    const onAddPhotoGroup = useCallback(async (): Promise<
        PhotoGroupRead & {
            documents: PhotoRead[];
        }
    > => {
        const id = defaultValues?.id;
        if (!id) {
            throw new Error('Inspection id is undefined');
        }

        const group = await createPhotoGroup({
            photoGroup: {
                name: 'Новая группа',
                inspection: defaultValues.id,
            },
        }).unwrap();

        return {
            ...group,
            documents: group.photos,
        };
    }, [createPhotoGroup, defaultValues?.id]);

    const onRenamePhotoGroup = useCallback(
        async (id: number, name: string): Promise<void> => {
            await partialUpdatePhotoGroup({
                id,
                patchedPhotoGroup: { name },
            }).unwrap();
        },
        [partialUpdatePhotoGroup],
    );

    const onDeletePhotoGroup = useCallback(
        async (id: number) => {
            await destroyPhotoGroup({ id }).unwrap();
        },
        [destroyPhotoGroup],
    );

    const [destroyPhoto] = useApiV1InspectionsPhotosDestroyMutation();
    const [createPhoto] = useApiV1InspectionsPhotosCreateMutation();
    const [updatePhoto] = useApiV1InspectionsPhotosPartialUpdateMutation();

    const onUploadPhoto = useCallback(
        async (
            id: number,
            groupId: number,
            position: number,
        ): Promise<PhotoRead> => {
            return await createPhoto({
                photo: {
                    image: null,
                    photoGroup: groupId,
                    file: id,
                    position,
                },
            }).unwrap();
        },
        [createPhoto],
    );

    const onDeletePhoto = useCallback(
        async (id: number) => {
            await destroyPhoto({ id }).unwrap();
        },
        [destroyPhoto],
    );

    const onMovePhotoGroup = useCallback(
        async (id: number, group: number) => {
            return await updatePhoto({
                id,
                patchedPhoto: { photoGroup: group },
            }).unwrap();
        },
        [updatePhoto],
    );

    const onUpdatePositionFile = useCallback(
        async (id: number, position: number) => {
            return await updatePhoto({
                id,
                patchedPhoto: { position },
            }).unwrap();
        },
        [updatePhoto],
    );
    return (
        <Form onSubmit={onHandleSubmit} id={formId} inProgress={isProgress}>
            <AddPropIsLoading isLoading={isLoading}>
                <Section title={'Описание здания, подъезда'}>
                    <SelectForm
                        label={'Состояние подъезда'}
                        formKey={'entranceCondition'}
                        register={register}
                        options={[
                            { name: 'Хорошее', value: 'Хорошее' },
                            {
                                name: 'Удовлетворительное',
                                value: 'Удовлетворительное',
                            },
                            {
                                name: 'Неудовлетворительное',
                                value: 'Неудовлетворительное',
                            },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.entranceCondition}
                        attrs={{
                            ...register('entranceCondition', {}),
                        }}
                    />
                    <SelectForm
                        label={'Состояние крыши'}
                        formKey={'roofCondition'}
                        register={register}
                        options={[
                            { name: 'Хорошее', value: 'Хорошее' },
                            {
                                name: 'Удовлетворительное',
                                value: 'Удовлетворительное',
                            },
                            {
                                name: 'Неудовлетворительное',
                                value: 'Неудовлетворительное',
                            },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.roofCondition}
                        attrs={{
                            ...register('roofCondition', {}),
                        }}
                    />
                </Section>
                <Section title={'Описание Объекта оценки'}>
                    <SelectForm
                        formKey={'visibleFinishingDefects'}
                        label={'Видимые дефекты отделки'}
                        register={register}
                        defaultValue={'Нет'}
                        options={[
                            { name: 'Да', value: 'Да' },
                            { name: 'Нет', value: 'Нет' },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.visibleFinishingDefects}
                        attrs={{
                            ...register('visibleFinishingDefects', {}),
                        }}
                    />
                    <SelectForm
                        formKey={'windowView'}
                        label={'Вид из окна'}
                        register={register}
                        options={[WindowsView.YARD, WindowsView.STREET].map(
                            (a) => ({
                                value: a.toString(),
                                name: WindowsViewLabels[a],
                            }),
                        )}
                        emptyValue={'Выберите значение'}
                        error={errors.windowView}
                        attrs={{
                            ...register('windowView', {}),
                        }}
                    />
                    <SelectForm
                        formKey={'leaksOnCeiling'}
                        label={'Наличие следов протечек на потолке'}
                        register={register}
                        defaultValue={'Нет'}
                        options={[
                            { name: 'Да', value: 'true' },
                            { name: 'Нет', value: 'false' },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.leaksOnCeiling}
                        attrs={{
                            ...register('leaksOnCeiling', {}),
                        }}
                    />
                </Section>
                <Section title={'Описание инженерного оборудования'}>
                    <SelectForm
                        formKey={'kitchenStove'}
                        label={'Кухонная плита'}
                        register={register}
                        options={[
                            {
                                name: 'Не установлена',
                                value: 'Не установлена',
                            },
                            { name: 'Газовая', value: 'Газовая' },
                            {
                                name: 'Электрическая',
                                value: 'Электрическая',
                            },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.kitchenStove}
                        attrs={{
                            ...register('kitchenStove', {}),
                        }}
                    />
                    <SelectForm
                        formKey={'plumbingCondition'}
                        label={'Сантехника/состояние'}
                        register={register}
                        options={[
                            {
                                name: 'Не установлена',
                                value: 'Не установлена',
                            },
                            {
                                name: 'Установлена/хорошее',
                                value: 'Установлена/хорошее',
                            },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.plumbingCondition}
                        attrs={{
                            ...register('plumbingCondition', {}),
                        }}
                    />
                    <SelectForm
                        formKey={'kitchenArrangement'}
                        label={'Обустройство кухни'}
                        register={register}
                        options={[
                            {
                                name: 'Не обустроена',
                                value: 'Не обустроена',
                            },
                            { name: 'Стандарт', value: 'Стандарт' },
                            { name: 'Встроенная', value: 'Встроенная' },
                        ]}
                        emptyValue={'Выберите значение'}
                        error={errors.kitchenArrangement}
                        attrs={{
                            ...register('kitchenArrangement', {}),
                        }}
                    />
                </Section>
                <Section title={'Описание внутренней отделки'}>
                    <Field formKey={'floors'} label={'Полы'}>
                        <Controller
                            control={control}
                            name={'floors'}
                            render={({ field }) => (
                                <MultiCombobox
                                    {...field}
                                    values={'Керамическая плитка/Керамогранит, Ламинат, ПВХ-плитка, Линолеум, Стяжка, Без отделки'.split(
                                        ', ',
                                    )}
                                    error={errors.floors}
                                />
                            )}
                        />
                    </Field>
                    <Field formKey={'ceiling'} label={'Потолки'}>
                        <Controller
                            control={control}
                            name={'ceiling'}
                            render={({ field }) => (
                                <MultiCombobox
                                    {...field}
                                    values={'Натяжной, Окрашено, Из ГКЛ, Потолочная плитка, Без отделки'.split(
                                        ', ',
                                    )}
                                    error={errors.ceiling}
                                />
                            )}
                        />
                    </Field>
                    <Field formKey={'walls'} label={'Стены'}>
                        <Controller
                            control={control}
                            name={'walls'}
                            render={({ field }) => (
                                <MultiCombobox
                                    {...field}
                                    values={'Керамическая плитка/Керамогранит, Окрашено, Обои, Стеновые панели, Без отделки'.split(
                                        ', ',
                                    )}
                                    error={errors.walls}
                                />
                            )}
                        />
                    </Field>
                    <Field formKey={'windows'} label={'Окна'}>
                        <Controller
                            control={control}
                            name={'windows'}
                            render={({ field }) => (
                                <MultiCombobox
                                    {...field}
                                    values={'Пластиковые, Деревянные'.split(
                                        ', ',
                                    )}
                                    error={errors.windows}
                                />
                            )}
                        />
                    </Field>
                    <Field
                        formKey={'doors'}
                        label={'Входная дверь / внутренние двери'}>
                        <Controller
                            control={control}
                            name={'doors'}
                            render={({ field }) => (
                                <MultiCombobox
                                    {...field}
                                    values={'Металлическая / филенчатые, Металлическая / деревянные, Деревянная / филенчатые, Деревянная / деревянные филенчатые, Металлическая / не установлены'.split(
                                        ', ',
                                    )}
                                    error={errors.doors}
                                />
                            )}
                        />
                    </Field>
                    <SelectForm
                        formKey={'renovationType'}
                        label={'Общее состояние помещений'}
                        register={register}
                        options={[
                            RenovationTypes.UNKNOWN,
                            RenovationTypes.NO,
                            RenovationTypes.ROUGH,
                            RenovationTypes.PRECLEAN,
                            RenovationTypes.COSMETIC,
                            RenovationTypes.EURO,
                            RenovationTypes.DESIGN,
                        ].map((a) => ({
                            value: a.toString(),
                            name: RenovationsLabels[a],
                        }))}
                        emptyValue={'Выберите значение'}
                        error={errors.renovationType}
                        attrs={{
                            ...register('renovationType', {}),
                        }}
                    />
                    <ControlCombobox
                        control={control}
                        name={'necessaryRenovations'}
                        label="Необходимые ремонтные работы"
                        isLoading={isLoading}
                        error={errors.necessaryRenovations}
                        className="col-span-3"
                        options={[
                            {
                                name: 'Полный комплекс ремонтных работ',
                                value: 'Полный комплекс ремонтных работ',
                            },
                            {
                                name: 'Проведение ремонтных работ не требуется',
                                value: 'Проведение ремонтных работ не требуется',
                            },
                            {
                                name: 'Требуется проведение косметического ремонта',
                                value: 'Требуется проведение косметического ремонта',
                            },
                        ]}
                    />
                    <SelectForm
                        formKey={'currentUsing'}
                        label={'Текущее использование Объекта оценки'}
                        options={'В качестве объекта жилого фонда, В качестве объекта нежилого фонда, Не используется'
                            .split(', ')
                            .map((v) => ({
                                value: v,
                                name: v,
                            }))}
                        register={register}
                        emptyValue={'Выберите значение'}
                        error={errors.currentUsing}
                        attrs={{
                            ...register('currentUsing', {}),
                        }}
                    />
                </Section>
                <Section title={'Описание планировки'}>
                    <SelectForm
                        formKey={'complianceLayout'}
                        label={
                            'Соответствие планировочных решений Объекта оценки указанным решениям в технической документации'
                        }
                        options={'Соответствует, Не соответствует'
                            .split(', ')
                            .map((v) => ({
                                value: v,
                                name: v,
                            }))}
                        register={register}
                        emptyValue={'Выберите значение'}
                        error={errors.complianceLayout}
                        attrs={{
                            ...register('complianceLayout', {}),
                        }}
                    />
                    <SelectForm
                        formKey={'unapprovedLayout'}
                        label={'Не разрешенная перепланировка'}
                        defaultValue={'Нет'}
                        options={'Да, Нет'.split(', ').map((v) => ({
                            value: v,
                            name: v,
                        }))}
                        register={register}
                        emptyValue={'Выберите значение'}
                        error={errors.unapprovedLayout}
                        attrs={{
                            ...register('unapprovedLayout', {}),
                        }}
                    />
                    <InputForm
                        formKey={'layoutChanges'}
                        label={'Описание перепланировок'}
                        register={register}
                        error={errors.layoutChanges}
                        attrs={{
                            ...register('layoutChanges', {}),
                        }}
                    />
                </Section>
                {defaultValues && (
                    <Section title={'Фотографии объекта оценки'}>
                        <div className="col-span-full">
                            <DocumentsGroup
                                formKey={'photoGroups'}
                                label={'Фотографии'}
                                onDeleteGroup={onDeletePhotoGroup}
                                onRenameGroup={onRenamePhotoGroup}
                                onDeleteFile={onDeletePhoto}
                                onUploadFile={onUploadPhoto}
                                onMoveFileGroup={onMovePhotoGroup}
                                groups={documentsGroup}
                                onAddNewGroup={onAddPhotoGroup}
                                onUpdatePositionFile={onUpdatePositionFile}
                            />
                        </div>
                    </Section>
                )}
            </AddPropIsLoading>
        </Form>
    );
};
