.dialogContent {
    @apply relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6;
}
.size-sm {
    @apply sm:max-w-lg;
}
.size-md {
    @apply sm:max-w-xl;
}
.size-lg {
    @apply sm:max-w-4xl;
}
.size-xl {
    @apply sm:max-w-6xl;
}
.size-full {
    @apply sm:w-full;
}
.title {
    @apply mb-4 pr-4 text-2xl font-semibold leading-6;
}
