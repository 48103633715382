import { AdvertisementRead } from '@ocenkatech/common/api';
import {
    RenovationsLabels,
    SummerRoomsLabels,
    WallTypesLabels,
    WindowsViewLabels,
} from '@ocenkatech/common/const';
import React from 'react';

type AdvertisementTableProps = {
    advertisements: Array<AdvertisementRead>;
};
export const AdvertisementTable: React.FC<AdvertisementTableProps> = ({
    advertisements,
}) => {
    return (
        <div className="relative overflow-x-auto">
            <table className="w-full text-left text-sm text-gray-500">
                <tbody>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Адрес
                        </th>
                        {advertisements.map((advertisement) => (
                            <td
                                key={advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {advertisement.address}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Источник
                        </th>
                        {advertisements.map((advertisement) => (
                            <td
                                key={advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-blue-500">
                                <a href={advertisement.sourceUrl}>
                                    {advertisement.sourceUrl}
                                </a>
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Контакт
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.phone}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Цена
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.price} (
                                {(analog.price / analog.area).toFixed(0)})
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Площадь
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.area}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Город
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.city}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Метро
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.metro}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Расстояние до метро
                        </th>

                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.kmDoMetro}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Тип стен
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.wallType &&
                                    WallTypesLabels[analog.wallType]}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Этаж
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.floor}/{analog.floorsCount}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Количество комнат
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.roomsCount}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Ремонт
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog?.renovationType &&
                                    RenovationsLabels[analog.renovationType]}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Вид из окна
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog?.windowsView &&
                                    WindowsViewLabels[analog.windowsView]}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Летние помещения
                        </th>
                        {advertisements.map((analog) => (
                            <td
                                key={analog.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog?.summerRooms &&
                                    SummerRoomsLabels[analog.summerRooms]}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
