import {
    ApartmentRead,
    Building,
    ComplexRead,
    AssessmentObject,
    useLazyApiV1ResidentialApartmentsListQuery,
    useLazyApiV1ResidentialBuildingsListQuery,
    useLazyApiV1ResidentialComplexesListQuery,
} from '@ocenkatech/common/api';
import { Polygon as GeoPolygon, pointToCoords } from '@ocenkatech/common/lib';
import { Combobox, Option } from '@ocenkatech/common/ui/Combobox';
import { Maps } from '@ocenkatech/common/ui/Maps';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface ResidentialComplexProps {
    city?: number | null;
    isLoading?: boolean;
}

export const ResidentialComplex: FC<ResidentialComplexProps> = ({
    city,
    isLoading,
}) => {
    const { setValue, watch } = useFormContext<AssessmentObject>();
    const [residentialComplexId, residentialComplexBuildingId] = watch([
        'residentialComplex',
        'residentialComplexBuilding',
    ]);
    const [center, setCenter] = useState<[number, number]>([0, 0]);
    const [selectComplex, setSelectComplex] = useState<ComplexRead>();
    const [selectBuilding, setSelectBuilding] = useState<Building>();
    const [selectApartment, setSelectApartment] = useState<ApartmentRead>();

    const [
        fetchComplexes,
        { currentData: complexes, isLoading: isLoadingComplex },
    ] = useLazyApiV1ResidentialComplexesListQuery();

    const [
        fetchBuildings,
        { currentData: buildings, isLoading: isLoadingBuildings },
    ] = useLazyApiV1ResidentialBuildingsListQuery();

    const [
        fetchApartments,
        { currentData: apartments, isLoading: isLoadingApartments },
    ] = useLazyApiV1ResidentialApartmentsListQuery();

    const onSelectResidentialComplex = useCallback(
        (select: Option | string | number) => {
            let selectValue = 0;
            if (typeof select === 'string' || typeof select === 'number') {
                selectValue = Number(select);
            } else {
                selectValue = Number(select.value);
            }

            const complex = complexes?.find((rc) => rc.id === selectValue);

            if (!complex) return;

            setValue('residentialComplex', selectValue);
            setValue('residentialComplexName', complex.name);
            complex?.subject && setValue('subjectRf', complex.subject);
            complex?.locality && setValue('locality', complex.locality);
            setValue('residentialComplexBuilding', null);
            setValue('address', '');
            setValue('kadastrNumber', undefined);
            setValue('residentialComplexApartment', null);
            setValue('apartmentNumber', '');
        },
        [complexes, setValue],
    );

    const onSelectBuilding = useCallback(
        (select: Option | string | number) => {
            let selectValue = 0;
            if (typeof select === 'string' || typeof select === 'number') {
                selectValue = Number(select);
            } else {
                selectValue = Number(select.value);
            }
            const currentBuilding = buildings?.find(
                (b) => b.id === selectValue,
            );
            setSelectBuilding(currentBuilding);
            setValue('residentialComplexBuilding', selectValue);
            currentBuilding?.address &&
                setValue('address', currentBuilding.address);
            currentBuilding?.floorCountMax &&
                setValue('buildingFloorsCount', currentBuilding.floorCountMax);

            setValue('residentialComplexApartment', null);
            setValue('apartmentNumber', '');
        },
        [buildings, setValue],
    );

    const onSelectApartment = useCallback(
        (select: Option | string | number) => {
            let selectValue = 0;
            if (typeof select === 'string' || typeof select === 'number') {
                selectValue = Number(select);
            } else {
                selectValue = Number(select.value);
            }

            const currentApartment = apartments?.find(
                (a) => a.id === selectValue,
            );
            setSelectApartment(currentApartment);
            setValue('residentialComplexApartment', selectValue);
            currentApartment?.number &&
                setValue('apartmentNumber', currentApartment.number);
        },
        [apartments, setValue],
    );

    const buildingsPlacemarks = useMemo(
        () =>
            buildings?.map((building) => ({
                // geometry: pointToCoords(building?.location || ''),
                options: {
                    iconColor:
                        selectBuilding?.id === building.id ? 'green' : 'red',
                    zIndex: 1,
                },
                onClick: () => {
                    if (selectBuilding?.id === building.id) return;

                    onSelectBuilding({
                        name: building?.address || '',
                        value: building.id,
                    });
                },
            })),
        [buildings, onSelectBuilding, selectBuilding?.id],
    );

    useEffect(() => {
        if (city) {
            fetchComplexes({ city });
        }
    }, [fetchComplexes, city]);

    useEffect(() => {
        const complexId = selectComplex?.id || residentialComplexId;

        if (complexId) {
            fetchBuildings({
                complex: complexId,
            });
        }
    }, [fetchBuildings, residentialComplexId, selectComplex?.id]);

    useEffect(() => {
        const buildingId = selectBuilding?.id || residentialComplexBuildingId;

        if (buildingId) {
            fetchApartments({
                building: buildingId,
            });
        }
    }, [fetchApartments, residentialComplexBuildingId, selectBuilding?.id]);

    useEffect(() => {
        if (complexes?.length) {
            if (residentialComplexId) {
                setSelectComplex(
                    complexes?.find((c) => c.id === residentialComplexId),
                );
            }
        }
    }, [complexes, residentialComplexId]);

    useEffect(() => {
        if (buildings?.length && residentialComplexBuildingId) {
            const currentComplex = buildings?.find(
                (h) => h.id === residentialComplexBuildingId,
            );
            setSelectBuilding(currentComplex);
            setCenter(pointToCoords(/*currentComplex?.location || */ ''));
        }
    }, [buildings, residentialComplexBuildingId]);

    useEffect(() => {
        if (selectComplex) {
            onSelectResidentialComplex({
                name: selectComplex.name,
                value: String(selectComplex.id),
            });
        }
    }, [onSelectResidentialComplex, selectComplex]);

    useEffect(() => {
        if (selectBuilding) {
            onSelectBuilding({
                name: selectBuilding?.address || '',
                value: String(selectBuilding.id),
            });
        }
    }, [onSelectBuilding, selectBuilding]);

    return (
        <>
            <Maps
                state={{ center: center, zoom: 15 }}
                isLoading={isLoading}
                placemarks={buildingsPlacemarks}
                polygon={{
                    geometry: [
                        GeoPolygon.parse(
                            String(selectComplex?.polygon) ?? '',
                        ).coords.map((c) => [
                            c.lat.toFixed(6),
                            c.long.toFixed(6),
                        ]),
                    ],
                    options: {
                        strokeColor: 'blue',
                        opacity: 0.2,
                        strokeWidth: 1,
                    },
                }}
            />

            <Combobox
                label={'Жилой комплекс'}
                className="col-span-full"
                isLoading={isLoading || isLoadingComplex}
                value={
                    selectComplex
                        ? {
                              name: selectComplex.name,
                              value: selectComplex.id,
                          }
                        : undefined
                }
                options={
                    complexes?.map((complex) => ({
                        name: complex.name,
                        value: String(complex.id),
                    })) || []
                }
                onChange={onSelectResidentialComplex}
            />
            <Combobox
                label={'Дом'}
                className="col-span-3"
                isLoading={isLoading || isLoadingBuildings}
                value={
                    selectBuilding
                        ? {
                              name: selectBuilding.address || '',
                              value: selectBuilding.id,
                          }
                        : undefined
                }
                onChange={onSelectBuilding}
                options={
                    buildings?.map((b) => ({
                        name: b.address!,
                        value: String(b.id),
                    })) || []
                }
            />
            <Combobox
                label={'Квартира'}
                className="col-span-3"
                isLoading={isLoading || isLoadingApartments}
                hint="Только для супер-сервиса"
                value={
                    selectApartment
                        ? {
                              name: selectApartment.number.toString(),
                              value: selectApartment.id,
                          }
                        : undefined
                }
                onChange={onSelectApartment}
                options={
                    apartments?.map((a) => ({
                        name: String(a.number),
                        value: String(a.id),
                    })) || []
                }
            />
        </>
    );
};
