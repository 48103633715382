import { PlusIcon } from '@heroicons/react/20/solid';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import {
    Status456Enum,
    useApiV1ReportsListQuery,
} from '@ocenkatech/common/api';
import { handleError, useSaveUrlParams } from '@ocenkatech/common/lib';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { ErrorMessage } from '@ocenkatech/common/ui/ErrorMessage';
import { HeadingTabs } from '@ocenkatech/common/ui/Heading';
import { Pagination } from '@ocenkatech/common/ui/Pagination';
import { Table } from '@ocenkatech/common/ui/Table';
import { useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReportsStatus, ReportsStatusLabels } from 'entities/reports';

const pageSize = 10;

export const ReportsTable = () => {
    const [page, setPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState<ReportsStatus>('');
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [
            { name: 'status', value: filterStatus, setValue: setFilterStatus },
            { name: 'page', value: page, setValue: setPage },
        ],
        [filterStatus, page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });

    const {
        currentData: reports,
        isLoading,
        isFetching,
        error,
    } = useApiV1ReportsListQuery({
        page,
        pageSize,
        status: filterStatus as Status456Enum,
    });

    const totalsReports = useRef<number>();
    const filterTabs = Object.entries(ReportsStatusLabels).map(
        ([status, name]) => ({ name, value: status }),
    );

    const prepareReports = useMemo(
        () =>
            reports?.results?.map((report) => ({
                id: report.id,
                status: ReportsStatusLabels[report.status!],
                timeChange: new Date(
                    report.modified || report.created,
                ).toLocaleString(),
            })) ?? [],
        [reports?.results],
    );

    if (reports?.count && totalsReports.current !== reports?.count) {
        totalsReports.current = reports.count;
    }

    if (error) {
        return (
            <ErrorMessage
                message={handleError(error)}
                className="bg-white py-8"
            />
        );
    }

    return (
        <>
            <div className="bg-white pb-6 pt-10">
                <div className="px-4 sm:px-6 lg:px-8">
                    <HeadingTabs
                        namePage="Отчеты"
                        tabs={filterTabs}
                        currentTab={filterStatus}
                        onChangeTab={(status) => {
                            setPage(1);
                            setFilterStatus(status);
                        }}
                    />
                </div>
            </div>
            {reports?.count === 0 ? (
                <div className="bg-white px-4 py-12">
                    <div className="text-center">
                        <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Отчетов нет
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Начните с создания нового заказа или нового подбора.
                        </p>
                        <div className="mt-6 flex justify-center gap-4">
                            <Link to={'/inspection/new'}>
                                <Button icon theme={ButtonTheme.SECONDARY}>
                                    <PlusIcon className="h-5 w-5" />
                                    Новый подбор
                                </Button>
                            </Link>
                            <Link to={'/applications/new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новая заявка
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <Table
                            isLoading={isFetching}
                            rows={prepareReports}
                            columns={[
                                { key: 'id', className: 'w-[1%]' },
                                { key: 'status' },
                                { key: 'timeChange' },
                            ]}
                            columnsName={['#', 'Статус', 'Дата изменения']}
                            onClick={(el) => navigate(`/reports/${el.id}`)}
                        />
                    </div>
                    <Pagination
                        isLoading={isLoading}
                        onPageChange={setPage}
                        totalItems={totalsReports.current || reports?.count}
                        currentPage={page}
                        itemsPerPage={pageSize}
                    />
                </>
            )}
        </>
    );
};
