import { useApiV1BuildingsHouseRetrieveQuery } from '@ocenkatech/common/api';
import {
    HouseTypeLabels,
    RenovationsLabels,
    SummerRoomsLabels,
    WallTypesLabels,
} from '@ocenkatech/common/const';
import {
    HouseType,
    WallTypes,
    RenovationTypes,
    SummerRooms,
} from '@ocenkatech/common/types';
import { Button } from '@ocenkatech/common/ui/Button';
import { Form } from '@ocenkatech/common/ui/Form';
import { Loader } from '@ocenkatech/common/ui/Loader';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AddressAutocomplete } from 'features/autoComplete';
import { Suggest } from 'entities/buildings';
import { SelectionSchema } from '../../model/SelectionSchema';

type SelectionFormProps = {
    isLoading?: boolean;
    onSubmit: (params: SelectionSchema) => void;
};

export const SelectionForm: React.FC<SelectionFormProps> = ({
    onSubmit,
    isLoading,
}) => {
    const { handleSubmit, register, setValue } = useForm<SelectionSchema>();
    const [suggestData, setSuggestData] = useState<Suggest | undefined>(
        undefined,
    );

    useEffect(() => {
        if (
            suggestData?.data?.fiasLevel &&
            suggestData?.data?.fiasLevel >= '8' &&
            suggestData?.value !== null
        ) {
            setValue('address', suggestData.value);
            setValue(
                'location',
                `POINT(${suggestData.data.geoLat} ${suggestData.data.geoLon})`,
            );

            // setValue('dadataData', fiasData);//todo: fix change generated Api
        }
    }, [
        setValue,
        suggestData?.data?.fiasLevel,
        suggestData?.data?.geoLat,
        suggestData?.data?.geoLon,
        suggestData?.value,
    ]);

    const { currentData: houseData, isLoading: isLoadingHouseData } =
        useApiV1BuildingsHouseRetrieveQuery(
            { houseId: suggestData?.data?.houseFiasId ?? '' },
            { skip: !suggestData?.data?.houseFiasId.length ?? true },
        );

    useEffect(() => {
        if (!houseData) {
            return;
        }

        setValue('building', houseData.id);

        if (houseData?.floorCountMax) {
            setValue('maxFloor', houseData.floorCountMax);
        }

        if (houseData?.builtYear) {
            setValue('builtYear', houseData.builtYear);
        }

        if (Number(houseData?.builtYear) < new Date().getFullYear()) {
            setValue('houseType', HouseType.RESALE);
        }

        switch (houseData?.wallMaterial?.toLowerCase()) {
            case 'кирпич': {
                setValue('wallType', WallTypes.BRICK);
                break;
            }
            case 'монолит':
                setValue('wallType', WallTypes.MONOLITH);
                break;
            case 'панель':
                setValue('wallType', WallTypes.PANEL);
                break;
            case 'дерев':
                setValue('wallType', WallTypes.WOODEN);
                break;
        }
    }, [houseData, setValue]);

    return (
        <Form inProgress={isLoading} onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-3">
                <AddressAutocomplete onChangeFiasData={setSuggestData} />
                <div className="relative ">
                    {isLoadingHouseData && (
                        <div className="absolute inset-0 z-10 flex items-center justify-center after:absolute after:inset-0 after:block after:animate-pulse after:rounded-md after:bg-gray-200 after:opacity-50 after:content-['']">
                            <Loader className="z-10 size-10 opacity-100" />
                        </div>
                    )}
                    <div className="grid grid-cols-3 gap-2">
                        <div className="grid grid-cols-2 gap-1">
                            <div>
                                <label
                                    htmlFor="first_name"
                                    className="mb-2 block text-sm font-medium text-gray-900">
                                    Этаж
                                </label>
                                <input
                                    type="number"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500"
                                    {...register('floor', {
                                        required: true,
                                        min: 1,
                                        max: 99,
                                    })}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="first_name"
                                    className="mb-2 block text-sm font-medium text-gray-900">
                                    Макс. этаж
                                </label>
                                <input
                                    type="number"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500"
                                    {...register('maxFloor', {
                                        required: true,
                                        min: 1,
                                        max: 99,
                                    })}
                                />
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-1">
                            <div>
                                <label
                                    htmlFor="first_name"
                                    className="mb-2 block text-sm font-medium text-gray-900">
                                    Кол-во комнат
                                </label>
                                <input
                                    type="number"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500"
                                    {...register('roomsCount', {
                                        required: true,
                                        min: 1,
                                        max: 99,
                                    })}
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="first_name"
                                    className="mb-2 block text-sm font-medium text-gray-900">
                                    Площадь
                                </label>
                                <input
                                    type="number"
                                    step="0.1"
                                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500"
                                    {...register('area', {
                                        required: true,
                                        min: 1,
                                    })}
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="buildYear"
                                className="mb-2 block text-sm font-medium text-gray-900">
                                Год постройки
                            </label>
                            <input
                                type="number"
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500"
                                {...register('builtYear', {
                                    required: true,
                                    min: 1800,
                                    max: new Date().getFullYear(),
                                })}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-2">
                        <div>
                            <label
                                htmlFor="tabs"
                                className="mb-2 block text-sm font-medium text-gray-900">
                                Вид объекта
                            </label>
                            <select
                                {...register('houseType', { required: true })}
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500">
                                <option disabled defaultChecked>
                                    Выберите тип объекта
                                </option>
                                <option value={HouseType.NEW}>
                                    {HouseTypeLabels[HouseType.NEW]}
                                </option>
                                <option value={HouseType.RESALE}>
                                    {HouseTypeLabels[HouseType.RESALE]}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="tabs"
                                className="mb-2 block text-sm font-medium text-gray-900">
                                Тип ремонта
                            </label>
                            <select
                                {...register('renovationType', {
                                    required: true,
                                })}
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500">
                                <option disabled defaultChecked>
                                    Выберите тип ремонта
                                </option>
                                <option value={RenovationTypes.NO}>
                                    {RenovationsLabels[RenovationTypes.NO]}
                                </option>
                                <option value={RenovationTypes.COSMETIC}>
                                    {
                                        RenovationsLabels[
                                            RenovationTypes.COSMETIC
                                        ]
                                    }
                                </option>
                                <option value={RenovationTypes.EURO}>
                                    {RenovationsLabels[RenovationTypes.EURO]}
                                </option>
                                <option value={RenovationTypes.DESIGN}>
                                    {RenovationsLabels[RenovationTypes.DESIGN]}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="tabs"
                                className="mb-2 block text-sm font-medium text-gray-900">
                                Летние помещения
                            </label>
                            <select
                                {...register('summerRooms', { required: true })}
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500">
                                <option disabled defaultChecked>
                                    Выберите тип летних помещений
                                </option>
                                <option value={SummerRooms.NO}>
                                    {SummerRoomsLabels[SummerRooms.NO]}
                                </option>
                                <option value={SummerRooms.BALCONY}>
                                    {SummerRoomsLabels[SummerRooms.BALCONY]}
                                </option>
                                <option value={SummerRooms.LOGGIA}>
                                    {SummerRoomsLabels[SummerRooms.LOGGIA]}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label
                                htmlFor="tabs"
                                className="mb-2 block text-sm font-medium text-gray-900">
                                Материал стен
                            </label>
                            <select
                                {...register('wallType', { required: true })}
                                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500">
                                <option disabled defaultChecked>
                                    Выберите тип летних помещений
                                </option>
                                <option value={WallTypes.MONOLITH}>
                                    {WallTypesLabels[WallTypes.MONOLITH]}
                                </option>
                                <option value={WallTypes.PANEL}>
                                    {WallTypesLabels[WallTypes.PANEL]}
                                </option>
                                <option value={WallTypes.BLOCK}>
                                    {WallTypesLabels[WallTypes.BLOCK]}
                                </option>
                                <option value={WallTypes.BRICK}>
                                    {WallTypesLabels[WallTypes.BRICK]}
                                </option>
                                <option value={WallTypes.WOODEN}>
                                    {WallTypesLabels[WallTypes.WOODEN]}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <Button
                    loading={isLoading}
                    type={'submit'}
                    disabled={isLoading}>
                    Начать подбор
                </Button>
            </div>
        </Form>
    );
};
