import { ReportStatuses } from '../api/types';
import { ReportsStatus } from './types';

export const ReportStatusesLabels: Record<ReportStatuses, string> = {
    [ReportStatuses.CREATED]: 'Создается',
    [ReportStatuses.GENERATED]: 'Создан',
    [ReportStatuses.GENERATING]: 'Генерируется',
    [ReportStatuses.ERROR]: 'Ошибка',
    [ReportStatuses.SIGNED]: 'Подписан',
};

export const ReportsStatusLabels: Record<ReportsStatus, string> = {
    '': 'Все',
    created: 'Создан',
    generating: 'Генерируется',
    generated: 'Сгенерирован',
    signed: 'Подписан',
    error: 'Ошибка',
};
