import {
    useApiV1CitiesListQuery,
    useApiV1ResidentialComplexesListQuery,
    useLazyApiV1ResidentialApartmentsListQuery,
    useLazyApiV1ResidentialBuildingsListQuery,
} from '@ocenkatech/common/api';
import { RenovationsLabels, SummerRoomsLabels } from '@ocenkatech/common/const';
import { Polygon, useHandleErrors } from '@ocenkatech/common/lib';
import { RenovationTypes, SummerRooms } from '@ocenkatech/common/types';
import { Button } from '@ocenkatech/common/ui/Button';
import { Form } from '@ocenkatech/common/ui/Form';
import { Loader } from '@ocenkatech/common/ui/Loader';
import { SelectBase } from '@ocenkatech/common/ui/Select';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { renderInput, renderSelect } from 'features/fields';
import { SelectionSchema } from '../../model/SelectionSchema';

type CreateResidentialSelectionFormProps = {
    onSubmit: (cs: SelectionSchema) => void;
    error: FetchBaseQueryError | SerializedError | undefined;
    isLoading?: boolean;
};

export const CreateResidentialSelectionForm: React.FC<
    CreateResidentialSelectionFormProps
> = ({ onSubmit, error, isLoading }) => {
    const [selectedCity, setSelectedCity] = useState<string | null>(null);
    const {
        register,
        watch,
        formState: { errors },
        handleSubmit,
        setValue,
        setError,
    } = useForm<SelectionSchema>({
        defaultValues: {},
    });

    // const navigate = useNavigate();
    const {
        residentialComplex,
        residentialComplexHouse,
        residentialComplexApartment,
        // apartmentNumber,//todo: fix change generated Api
    } = watch();

    // const [create, { isLoading: isCreating, error }] =
    //     useApiV1SelectionsCreateMutation();

    const { currentData: cities, isLoading: isLoadingCities } =
        useApiV1CitiesListQuery();
    const {
        currentData: residentialComplexes,
        isFetching: residentialComplexesFetching,
    } = useApiV1ResidentialComplexesListQuery(
        selectedCity
            ? {
                  city: Number(selectedCity),
              }
            : {},
    );
    const [
        fetchBuildings,
        { currentData: buildings, isLoading: buildingsLoading },
    ] = useLazyApiV1ResidentialBuildingsListQuery();

    const [
        fetchApartments,
        { currentData: apartments, isLoading: apartmentsLoading },
    ] = useLazyApiV1ResidentialApartmentsListQuery();
    const build = useMemo(() => {
        if (residentialComplexHouse) {
            return buildings?.find((b) => b.id === +residentialComplexHouse);
        }
    }, [residentialComplexHouse, buildings]);

    const apartment = useMemo(() => {
        if (residentialComplexApartment) {
            return apartments?.find(
                (a) => a.id === +residentialComplexApartment,
            );
        }
    }, [residentialComplexApartment, apartments]);

    const complex = useMemo(() => {
        if (residentialComplex) {
            return residentialComplexes?.find(
                (rc) => rc.id === +residentialComplex,
            );
        }
    }, [residentialComplex, residentialComplexes]);

    useEffect(() => {
        if (complex) {
            fetchBuildings({ complex: complex.id });
            if (complex) {
                setValue(
                    'location',
                    Polygon.parse(complex.polygon).getCenter().toString(),
                );
                // setValue('address', complex.address);
            }
        }
    }, [complex, fetchBuildings, setValue]);

    useEffect(() => {
        if (build) {
            fetchApartments({ building: build.id });

            setValue('maxFloor', build.floorCountMax);
            setValue('builtYear', build.builtYear);
        }
    }, [build, fetchApartments, setValue]);

    useEffect(() => {
        if (apartment) {
            setValue('floor', apartment.floor);
            setValue('renovationType', apartment.renovationType);
            setValue('area', apartment.area);
            setValue('roomsCount', apartment.roomsCount);
            // setValue('apartmentNumber', apartment.number);//todo: fix change generated Api
            setValue('summerRooms', apartment.summerRoomsType);
        }
    }, [apartment, setValue]);

    useHandleErrors(error, setError);

    // const createSelection = useCallback(
    //     async (cs: CreateSelection) => {
    //         try {
    //             const { id } = await create({
    //                 createSelection: {
    //                     ...cs,
    //                     address: '', //`${house?.address}`, //todo:${apartmentNumber} fix change generated Api
    //                 },
    //             }).unwrap();

    //             navigate('/selection/' + id);
    //         } catch (e) {}
    //     },
    //     [create, /*house?.address,*/ navigate], //todo: fix change generated Api
    // );

    return (
        <>
            <Form inProgress={isLoading} onSubmit={handleSubmit(onSubmit)}>
                {/* <input type="text" {...register('type')} hidden /> */}
                <input type="text" {...register('address')} hidden />
                <input type="text" {...register('builtYear')} hidden />
                <input type="text" {...register('maxFloor')} hidden />
                <input type="text" {...register('location')} hidden />
                <SelectBase
                    formKey="city"
                    label="Город"
                    options={
                        cities?.map((c) => ({
                            name: c.name,
                            value: c.id.toString(),
                        })) ?? []
                    }
                    fieldClassName="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6 [&_select]:sm:max-w-xs [&_div]:sm:mt-0 [&_div]:sm:col-span-2 [&_label]:sm:pt-1.5"
                    isLoading={isLoadingCities}
                    attrs={{
                        defaultValue: '',
                        onChange: (event) =>
                            setSelectedCity(event.target.value),
                    }}
                    emptyValue="Выберите город"
                />

                {renderSelect({
                    formKey: 'residentialComplex',
                    label: 'ЖК',
                    options:
                        residentialComplexes?.map((rc) => ({
                            value: rc.id.toString(),
                            name: rc.name,
                        })) ?? [],
                    register,
                    emptyValue: 'Выберите ЖК',
                    isLoading: residentialComplexesFetching,
                })}
                {buildingsLoading ? <Loader /> : null}
                {complex && buildings ? (
                    <>
                        {renderSelect({
                            formKey: 'residentialComplexHouse',
                            label: 'Дом',
                            options:
                                buildings?.map((b) => ({
                                    value: b.id.toString(),
                                    name: b.address || '',
                                })) ?? [],
                            register,
                            emptyValue: 'Выберите дом',
                        })}
                    </>
                ) : null}

                {apartmentsLoading ? <Loader /> : null}
                {residentialComplexHouse ? (
                    <>
                        {renderSelect({
                            formKey: 'residentialComplexApartment',
                            label: 'Квартира',
                            options:
                                apartments?.map((a) => ({
                                    value: a.id.toString(),
                                    name: a.number.toString(),
                                })) ?? [],
                            hint: 'Выберите квартиру или введите данные вручную.',
                            emptyValue: 'Ввести данные вручную',
                            register,
                            error: errors.residentialComplexApartment,
                        })}
                        {/* {renderInput({
                            formKey: 'apartmentNumber',
                            label: 'Номер квартиры',
                            register,
                            type: 'number',
                        })} */}
                        {/*todo: fix change generated Api*/}
                        {renderInput({
                            formKey: 'floor',
                            label: 'Этаж',
                            register,
                            type: 'number',
                            error: errors.floor,
                        })}
                        {renderInput({
                            formKey: 'roomsCount',
                            label: 'Кол-во комнат',
                            register,
                            type: 'number',
                            error: errors.roomsCount,
                        })}
                        {renderInput({
                            formKey: 'area',
                            label: 'Площадь',
                            register,
                            props: {
                                type: 'number',
                                step: '0.1',
                            },
                            error: errors.area,
                        })}
                        {renderSelect({
                            formKey: 'renovationType',
                            label: 'Ремонт',
                            options:
                                [
                                    RenovationTypes.UNKNOWN,
                                    RenovationTypes.NO,
                                    RenovationTypes.ROUGH,
                                    RenovationTypes.PRECLEAN,
                                    RenovationTypes.COSMETIC,
                                    RenovationTypes.EURO,
                                    RenovationTypes.DESIGN,
                                ].map((a) => ({
                                    value: a.toString(),
                                    name: RenovationsLabels[a],
                                })) ?? [],
                            register,
                        })}
                        {renderSelect({
                            formKey: 'summerRooms',
                            label: 'Летние помещения',
                            options:
                                [
                                    SummerRooms.UNKNOWN,
                                    SummerRooms.NO,
                                    SummerRooms.BALCONY,
                                    SummerRooms.LOGGIA,
                                ].map((sr) => ({
                                    value: sr.toString(),
                                    name: SummerRoomsLabels[sr],
                                })) ?? [],
                            register,
                        })}
                    </>
                ) : null}
                {complex && residentialComplexHouse && (
                    <Button type="submit">Запустить подбор</Button>
                )}
            </Form>
        </>
    );
};
