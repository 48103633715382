import {
    AssessmentObject,
    AssessmentObjectRead,
    ReadAssessmentObjectRead,
    useApiV1CrmAssessmentObjectCreateMutation,
    useApiV1CrmAssessmentObjectPartialUpdateMutation,
} from '@ocenkatech/common/api';
import { getErrorMessage, removeEmptyValue } from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import { Card } from '@ocenkatech/common/ui/Card';
import { DataItem, DataList } from '@ocenkatech/common/ui/DataList';
import { DialogApp, DialogSize } from '@ocenkatech/common/ui/DialogApp';
import { FC, useId, useState, useCallback, memo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { AssessmentObjectForm } from './AssessmentObjectForm';

interface AssessmentObjectCardProps {
    defaultValues?: ReadAssessmentObjectRead;
    city?: number | null;
    isLoading?: boolean;
    isView?: boolean;
    className?: string;
    onAfterCreate?: (props: AssessmentObjectRead) => void;
}

export const AssessmentObjectCard: FC<AssessmentObjectCardProps> = memo(
    ({ defaultValues, city, isLoading, isView, className, onAfterCreate }) => {
        const [isEdit, setIsEdit] = useState(false);
        const formId = useId();

        const [
            create,
            { data: createdAO, isLoading: isCreating, error: errorCreate },
        ] = useApiV1CrmAssessmentObjectCreateMutation();
        const [
            update,
            { data: updatedAO, isLoading: isUpdating, error: errorUpdate },
        ] = useApiV1CrmAssessmentObjectPartialUpdateMutation();

        const onSave = useCallback<SubmitHandler<AssessmentObject>>(
            async (values) => {
                try {
                    if (isView) return;

                    const preparedValues = removeEmptyValue(values);
                    if (defaultValues?.id) {
                        await update({
                            id: defaultValues.id,
                            patchedAssessmentObject: preparedValues,
                        }).unwrap();
                        alert('Объект оценки обновлен');
                    } else {
                        const result = await create({
                            assessmentObject: {
                                ...preparedValues,
                                location: 'POINT(0 0)',
                            },
                        }).unwrap();
                        onAfterCreate?.(result);
                        alert('Создан новый Объект оценки');
                    }
                } catch (error) {
                    alert(
                        getErrorMessage(
                            error,
                            'Произошла ошибка при сохранении',
                        ),
                    );
                }
            },
            [create, defaultValues?.id, isView, onAfterCreate, update],
        );

        const onShowEdit = useCallback(() => {
            setIsEdit(true);
        }, []);

        const assessmentObjectInfo: DataItem[] = [
            {
                name: 'Адрес',
                value:
                    createdAO?.address ||
                    updatedAO?.address ||
                    defaultValues?.address,
            },
        ];

        return (
            <>
                <Card onClick={onShowEdit} className={className}>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Объект оценки
                    </h3>
                    <div className="pt-6">
                        <DataList
                            items={assessmentObjectInfo}
                            isLoading={isLoading}
                        />
                    </div>
                </Card>
                <DialogApp
                    open={isEdit}
                    size={DialogSize.LG}
                    setOpen={setIsEdit}
                    title={
                        defaultValues?.id
                            ? 'Редактировать объект оценки'
                            : 'Создано объект оценки'
                    }
                    actionSuccess={
                        !isView && (
                            <Button
                                type="submit"
                                form={formId}
                                loading={isLoading || isCreating || isUpdating}>
                                Сохранить
                            </Button>
                        )
                    }>
                    <AssessmentObjectForm
                        formId={formId}
                        defaultValues={
                            /*updatedAO || createdAO || */ defaultValues //todo:documents
                        }
                        city={city}
                        onSave={onSave}
                        isLoading={isLoading}
                        isProgress={isCreating || isUpdating}
                        isView={isView}
                        error={errorCreate || errorUpdate}
                    />
                </DialogApp>
            </>
        );
    },
);
