import { CorrectedAnalogRead } from '@ocenkatech/common/api';
import {
    RenovationsLabels,
    SummerRoomsLabels,
    WallTypesLabels,
} from '@ocenkatech/common/const';
import { classnames } from '@ocenkatech/common/lib';
import React from 'react';

type AnalogsTableProps = {
    analogs: Array<CorrectedAnalogRead>;
};

const Correction: React.FC<{ correction?: number; absolute?: boolean }> = ({
    correction = 0,
    absolute = true,
}) => {
    return (
        <span
            className={classnames(
                correction > 0 ? 'text-green-500' : 'text-red-500',
            )}>
            {correction > 0 ? '+' : ''}
            {absolute ? (correction * 100).toFixed(1) : correction}
            {absolute ? '%' : 'р.'}
        </span>
    );
};

export const AnalogsTable: React.FC<AnalogsTableProps> = ({ analogs }) => {
    return (
        <div className="relative overflow-x-auto">
            <table className="w-full text-left text-sm text-gray-500">
                <tbody>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Адрес
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.address}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Источник
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-blue-500">
                                <a href={analog.advertisement.sourceUrl}>
                                    {analog.advertisement.sourceUrl}
                                </a>
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Контакт
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.phone}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Цена
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.price}
                                {analog.bargainingCorrection !== 0 && (
                                    <Correction
                                        correction={analog.bargainingCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Цена за кв. м.
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {(
                                    analog.advertisement.price /
                                    analog.advertisement.area
                                ).toFixed(0)}{' '}
                                \{' '}
                                <span
                                    className={classnames({
                                        'text-red-500':
                                            analog.advertisement.price /
                                                analog.advertisement.area >
                                            Number(
                                                analog.correctedPricePerMeter,
                                            ),
                                        'text-green-500':
                                            analog.advertisement.price /
                                                analog.advertisement.area <=
                                            Number(
                                                analog.correctedPricePerMeter,
                                            ),
                                    })}>
                                    {Number(
                                        analog.correctedPricePerMeter,
                                    ).toFixed(0)}
                                </span>
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Площадь
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.area}{' '}
                                {analog.areaCorrection !== 0 && (
                                    <Correction
                                        correction={analog.areaCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Город
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.city}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Метро
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.metro}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Расстояние до метро
                        </th>

                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.kmDoMetro}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Тип стен
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.wallType &&
                                    WallTypesLabels[
                                        analog.advertisement.wallType
                                    ]}
                                {analog.wallTypeCorrection !== 0 && (
                                    <Correction
                                        correction={analog.wallTypeCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Этаж
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.floor}/
                                {analog.advertisement.floorsCount}
                                {analog.floorCorrection !== 0 && (
                                    <Correction
                                        correction={analog.floorCorrection}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Количество комнат
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.roomsCount}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Ремонт
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.renovationType &&
                                    RenovationsLabels[
                                        analog.advertisement.renovationType
                                    ]}
                                {analog.renovationTypeCorrection !== 0 && (
                                    <Correction
                                        correction={
                                            analog.renovationTypeCorrection
                                        }
                                        absolute={false}
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                    <tr className="border-b bg-white">
                        <th className="sticky left-0 w-32 bg-gray-50 px-2 text-xs uppercase text-gray-700">
                            Летние помещения
                        </th>
                        {analogs.map((analog) => (
                            <td
                                key={analog.advertisement.id}
                                className="w-64 whitespace-nowrap px-6 py-4 font-medium  text-gray-900">
                                {analog.advertisement.summerRooms &&
                                    SummerRoomsLabels[
                                        analog.advertisement.summerRooms
                                    ]}
                                {analog.summerRoomsCorrection !== 0 && (
                                    <Correction
                                        correction={
                                            analog.summerRoomsCorrection
                                        }
                                    />
                                )}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
