import {
    DocumentRead,
    PatchedOrderCreate,
    UploadFileRead,
} from '@ocenkatech/common/api';
import { Card } from '@ocenkatech/common/ui/Card';
import { FC, useCallback } from 'react';
import { DropFile } from 'shared/ui/DropFile';

interface UploadReportCardProps {
    onUpdate: (args: PatchedOrderCreate) => Promise<void>;
    file?: UploadFileRead | DocumentRead | null;
    orderId?: number;
    isLoading?: boolean;
    isView?: boolean;
}

export const UploadReportCard: FC<UploadReportCardProps> = ({
    onUpdate,
    file,
    isLoading,
    isView,
}) => {
    const onUpdateFileId = useCallback(
        async (file: UploadFileRead) => {
            await onUpdate({
                reportFile: file.id,
            });
        },
        [onUpdate],
    );

    return (
        <Card title="Файл отчёта" className="col-span-full">
            <DropFile
                formKey="signFile"
                label=""
                file={file}
                accept="application/pdf"
                titleAccept="PDF до 10 мегабайт"
                onUpload={onUpdateFileId}
                onRemove={() => {
                    onUpdate({ reportFile: null });
                }}
                isLoading={isLoading}
                isView={isView}
                isEdit
            />
        </Card>
    );
};
