//@ts-nocheck
import { useApiV1InspectionsRetrieveQuery } from '@ocenkatech/common/api';
import { HeadingActions } from '@ocenkatech/common/ui/Heading';
// import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import { useParams } from 'react-router-dom';
import { InspectionDetail } from 'entities/inspections';

interface InspectionViewProps {}

export const InspectionView: React.FC<InspectionViewProps> = () => {
    const { id } = useParams<{ id: string }>();

    const {
        currentData: inspection,
        isLoading,
        error,
    } = useApiV1InspectionsRetrieveQuery({
        id: Number(id),
    });

    if (error) {
        return <>{error}</>;
    }

    return (
        <>
            <HeadingActions title={'Осмотр №' + id} />
            <div className="white mx-auto max-w-7xl bg-white px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                {/* {inspection?.order && (
                    <LinkApp
                        to={`/applications/${inspection?.order}`}
                        className="font-bold">
                        {'<-'} К заявке
                    </LinkApp>
                )} */}
                <InspectionDetail value={inspection} isLoading={isLoading} />
            </div>
        </>
    );
};
