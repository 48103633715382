import { baseQuery } from '@ocenkatech/common/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ListAPIRequestParams, ListAPIResponse } from 'app/api/types';
import { CreateSelection, Selection } from './types';

export const selectionsApi = createApi({
    reducerPath: 'selectionsApi',
    baseQuery,
    endpoints: (builder) => ({
        selections: builder.query<
            ListAPIResponse<Selection>,
            ListAPIRequestParams
        >({
            query: (params) => ({
                url: '/api/v1/selections/',
                params,
                method: 'GET',
            }),
        }),
        selection: builder.query<Selection, number>({
            query: (id) => ({
                url: `/api/v1/selections/${id}`,
                method: 'GET',
            }),
        }),
        createNewSelection: builder.mutation<Selection, CreateSelection>({
            query: (body) => ({
                url: '/api/v1/selections/',
                method: 'POST',
                body,
            }),
        }),
        checkAdvertisement: builder.mutation<
            string,
            { id: string; url: string }
        >({
            query: ({ id, url }) => ({
                url: `/api/v1/selections/${id}/check/`,
                method: 'POST',
                body: { url },
            }),
        }),
        restartSelection: builder.mutation<any, number>({
            query: (id) => ({
                url: `/api/v1/selections/${id}/restart/`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useSelectionsQuery,
    useSelectionQuery,
    useCreateNewSelectionMutation,
    useCheckAdvertisementMutation,
    useRestartSelectionMutation,
} = selectionsApi;
