import { FC, PropsWithChildren } from 'react';

interface SkeletonProps {
    title?: string;
    subtitle?: string;
}

export const Skeleton: FC<PropsWithChildren<SkeletonProps>> = ({
    title,
    subtitle,
    children,
}) => {
    return (
        <div className="grid animate-pulse grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            {(title || subtitle) && (
                <div>
                    {title && (
                        <div className="h-6 w-2/3 rounded bg-gray-200"></div>
                    )}
                    {subtitle && (
                        <div className="mt-1 h-5 w-2/3 rounded bg-gray-200"></div>
                    )}
                </div>
            )}
            <div className="max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid sm:grid-cols-6 md:col-span-2">
                {children}
            </div>
        </div>
    );
};
