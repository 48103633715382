import {
    Inspection,
    useApiV1InspectionsRetrieveQuery,
    useApiV1InspectionsPartialUpdateMutation,
} from '@ocenkatech/common/api';
import { getErrorMessage, removeEmptyValue } from '@ocenkatech/common/lib';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { HeadingActions } from '@ocenkatech/common/ui/Heading';
// import { LinkApp } from '@ocenkatech/common/ui/LinkApp';
import React, { useCallback, useMemo, useId } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { InspectionForm } from 'widgets/inspectionCard';

type InspectionEditProps = {};

export const InspectionEdit: React.FC<InspectionEditProps> = () => {
    const formId = useId();
    const { id: _id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const id = useMemo(() => {
        return Number.parseInt(_id!);
    }, [_id]);

    const { currentData: inspection, isLoading } =
        useApiV1InspectionsRetrieveQuery({
            id: id,
        });

    const [updateInspection, { error: errorUpdate, isLoading: isUpdating }] =
        useApiV1InspectionsPartialUpdateMutation();

    const onSaveAndCompeted = useCallback<SubmitHandler<Inspection>>(
        async (values) => {
            try {
                await updateInspection({
                    id,
                    patchedInspection: {
                        ...removeEmptyValue(values),
                        status: 'completed',
                    },
                }).unwrap();

                navigate(`/inspections/${id}/view`);
            } catch (error) {
                alert(getErrorMessage(error, 'Ошибка при сохранении'));
            }
        },
        [id, navigate, updateInspection],
    );

    const onUpdate = useCallback<SubmitHandler<Inspection>>(
        async (values) => {
            try {
                await updateInspection({
                    id,
                    patchedInspection: removeEmptyValue(values),
                }).unwrap();
                alert('Осмотр сохранен');
            } catch (error) {
                alert(getErrorMessage(error, 'Ошибка при сохранении'));
            }
        },
        [id, updateInspection],
    );

    return (
        <>
            <HeadingActions title={'Осмотр №' + id} />
            <div className="white mx-auto max-w-7xl bg-white px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                {/* {inspection?.order && (
                    <LinkApp
                        to={`/applications/${inspection?.order}`}
                        className="font-bold">
                        {'<-'} К заявке
                    </LinkApp>
                )} */}
                <InspectionForm
                    onSave={onSaveAndCompeted}
                    onUpdate={onUpdate}
                    formId={formId}
                    defaultValues={inspection}
                    isLoading={isLoading}
                    isProgress={isUpdating}
                    error={errorUpdate}
                />

                <div className="mt-6 flex items-center justify-start gap-x-6">
                    <Button
                        type={'submit'}
                        data-name="save"
                        form={formId}
                        disabled={isLoading || isUpdating}>
                        Завершить осмотр
                    </Button>
                    <Button
                        type={'submit'}
                        data-name="update"
                        form={formId}
                        theme={ButtonTheme.SECONDARY}
                        disabled={isLoading || isUpdating}>
                        Сохранить
                    </Button>
                </div>
            </div>
        </>
    );
};
