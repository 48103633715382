import * as Sentry from '@sentry/react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'app/store';
// eslint-disable-next-line import/order
import { App } from 'app/App';
import reportWebVitals from './reportWebVitals';
import './app/styles/index.css';
import '@ocenkatech/common/index.css';

Sentry.init({
    dsn: 'https://a99221fb73868ecf87a2b13458b35c88@o4506586451804160.ingest.sentry.io/4506614824763392',
    environment: 'prod',
    integrations: [],
});

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
            <Provider store={store}>
                <App />
            </Provider>
        </DndProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
