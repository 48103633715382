import {
    ArrowDownTrayIcon,
    BuildingOffice2Icon,
    ChartPieIcon,
    ClipboardDocumentListIcon,
    MagnifyingGlassIcon,
    MapIcon,
    SparklesIcon,
} from '@heroicons/react/24/outline';
import { Header } from '@ocenkatech/common/ui/Header';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ItemMenu, SideBar } from 'widgets/sideBar';

const navigation: ItemMenu[] = [
    {
        name: 'Города и регионы',
        href: '/cities',
        icon: MapIcon,
        children: [
            { name: 'Города', href: '/cities' },
            { name: 'Регионы', href: '/regions' },
        ],
    },
    {
        name: 'Объявления',
        href: '/imports',
        icon: ArrowDownTrayIcon,
    },
    {
        name: 'База зданий',
        href: '/buildings',
        icon: BuildingOffice2Icon,
    },
    {
        name: 'Заказы',
        href: '/applications',
        icon: ClipboardDocumentListIcon,
    },
    { name: 'Осмотры', href: '/inspections', icon: MagnifyingGlassIcon },
    { name: 'Подбор', href: '/selection', icon: SparklesIcon },
    { name: 'Отчеты', href: '/reports', icon: ChartPieIcon },
];
const userNavigation = [{ name: 'Выйти', href: '/logout' }];

export const BaseLayout: React.FC = () => {
    return (
        <>
            <div className="min-h-full">
                <Header
                    menu={navigation}
                    userMenu={userNavigation}
                    className="lg:hidden"
                />
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:!flex lg:w-72 lg:flex-col">
                    <SideBar menu={navigation} userMenu={userNavigation} />
                </div>
                <Outlet />
            </div>
        </>
    );
};
