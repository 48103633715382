import { OrderCreate } from '@ocenkatech/common/api';
import { HeadingActions } from '@ocenkatech/common/ui/Heading';
import { useForm } from 'react-hook-form';
import { generateForm } from 'shared/lib/generateForm';
import { citiesForm } from '../module/configForm';

export const CitiesTable = () => {
    const {
        register,
        formState: { errors },
    } = useForm<OrderCreate>();
    return (
        <>
            <HeadingActions title="Города" fullWidth />
            <div className="bg-white px-4 py-2 sm:px-6 sm:py-8 lg:px-8">
                {generateForm({
                    sections: citiesForm,
                    register,
                    errors,
                })}
            </div>
        </>
    );
};
