import { PlusIcon } from '@heroicons/react/20/solid';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import {
    OrderRead,
    Status81AEnum,
    useApiV1CrmOrdersListQuery,
} from '@ocenkatech/common/api';
import {
    handleError,
    useDebounce,
    useSaveUrlParams,
} from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import { Combobox } from '@ocenkatech/common/ui/Combobox';
import { ErrorMessage } from '@ocenkatech/common/ui/ErrorMessage';
import { HeadingActions } from '@ocenkatech/common/ui/Heading';
import { Pagination } from '@ocenkatech/common/ui/Pagination';
import { SearchInput } from '@ocenkatech/common/ui/Search';
import { Table } from '@ocenkatech/common/ui/Table';
import React, {
    ChangeEvent,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LabelOrderStatus, OrderStatus } from 'entities/appraisers';

const pageSize = 10;

export const ApplicationsTable: React.FC = () => {
    const [page, setPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState<OrderStatus>('draft');
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebounce(query, 250);
    const navigate = useNavigate();

    const urlParams = useMemo(
        () => [
            { name: 'status', value: filterStatus, setValue: setFilterStatus },
            { name: 'page', value: page, setValue: setPage },
        ],
        [filterStatus, page],
    );

    useSaveUrlParams({
        urlParams: urlParams,
    });

    const {
        currentData: orders,
        isLoading,
        isFetching,
        error,
    } = useApiV1CrmOrdersListQuery({
        page,
        pageSize,
        status: filterStatus as Status81AEnum,
        query: debouncedQuery,
    });

    const totalsOrders = useRef<number>();
    const filterTabs = Object.entries(LabelOrderStatus).map(
        ([status, name]) => ({
            name,
            value: status,
        }),
    );

    const applicationsWithCustomerName = useMemo(
        () =>
            orders?.results?.map((order: OrderRead) => ({
                ...order,
                customerName: order?.customer?.name,
                statusLabel: order.status && LabelOrderStatus[order.status],
                address: order?.assessmentObject?.address,
            })) ?? [],
        [orders?.results],
    );

    const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
        setFilterStatus('');
    }, []);

    if (orders?.count && totalsOrders.current !== orders?.count) {
        totalsOrders.current = orders.count;
    }

    if (error) {
        return (
            <ErrorMessage
                message={handleError(error)}
                className="bg-white py-8"
            />
        );
    }

    return (
        <>
            <div className="bg-white pb-6">
                <div className="border-b border-gray-200">
                    <HeadingActions title="Заказы" fullWidth>
                        <Link key={'new'} to={'new'}>
                            <Button icon>
                                <PlusIcon className="h-5 w-5" />
                                Новая заявка
                            </Button>
                        </Link>
                    </HeadingActions>
                </div>
                <div className="mt-6 flex flex-col gap-4 px-4 sm:flex-row sm:px-6 lg:px-8">
                    <SearchInput
                        onChange={onSearch}
                        className="w-full !max-w-full sm:!max-w-xs"
                    />
                    <Combobox
                        label=""
                        className="w-full max-w-full sm:max-w-xs"
                        value={filterStatus}
                        options={filterTabs}
                        onChange={(value) => {
                            setPage(1);
                            setFilterStatus(value as OrderStatus);
                        }}
                    />
                    <Button
                        onClick={() => {
                            setPage(1);
                            setFilterStatus('');
                        }}>
                        Сбросить
                    </Button>
                </div>
            </div>
            {orders?.count === 0 ? (
                <div className="bg-white px-4 py-12">
                    <div className="text-center">
                        <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            Заказов нет
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Начните с создания нового заказа.
                        </p>
                        <div className="mt-6">
                            <Link to={'/applications/new'}>
                                <Button icon>
                                    <PlusIcon className="h-5 w-5" />
                                    Новая заявка
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="overflow-x-auto">
                        <Table
                            isLoading={isFetching}
                            rows={applicationsWithCustomerName}
                            columns={[
                                { key: 'id', className: 'w-[1%]' },
                                { key: 'address' },
                                {
                                    key: 'customerName',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                                {
                                    key: 'statusLabel',
                                    className: 'w-1/12 whitespace-nowrap',
                                },
                            ]}
                            columnsName={['#', 'Адрес', 'Заказчик', 'Статус']}
                            onClick={(el) =>
                                navigate(
                                    `/applications/${el.id}${el?.status === 'draft' ? '/edit' : ''}`,
                                )
                            }
                        />
                    </div>
                    <Pagination
                        isLoading={isLoading}
                        onPageChange={setPage}
                        totalItems={totalsOrders.current || orders?.count}
                        currentPage={page}
                        itemsPerPage={pageSize}
                    />
                </>
            )}
        </>
    );
};
