import { OrderInspectionRead } from '@ocenkatech/common/api';
import { WindowsViewLabels } from '@ocenkatech/common/const';
import { DataItem, DataList } from '@ocenkatech/common/ui/DataList';
import { FC } from 'react';
interface InspectionDetailProps {
    value?: OrderInspectionRead;
    isLoading?: boolean;
}

export const InspectionDetail: FC<InspectionDetailProps> = ({
    value,
    isLoading,
}) => {
    const inspectionInfo: DataItem[] = [
        { name: 'Состояние подъезда', value: value?.roofCondition },
        {
            name: 'Видимые дефекты отделки',
            value: value?.visibleFinishingDefects,
        },
        {
            name: 'Вид из окна',
            value: value?.windowView && WindowsViewLabels[value.windowView],
        },
        {
            name: 'Наличие следов протечек на потолке',
            value: value?.leaksOnCeiling ? 'Да' : 'Нет',
        },
        { name: 'Кухонная плита', value: value?.kitchenStove },
        { name: 'Сантехника/состояние', value: value?.plumbingCondition },
        { name: 'Обустройство кухни', value: value?.kitchenArrangement },
        { name: 'Полы', value: value?.floors },
        { name: 'Потолки', value: value?.ceiling },
        { name: 'Стены', value: value?.walls },
        { name: 'Окна', value: value?.windows },
        { name: 'Входная дверь / внутренние двери', value: value?.doors },
        {
            name: 'Необходимые ремонтные работы',
            value: value?.necessaryRenovations,
        },
        {
            name: 'Текущее использование Объекта оценки',
            value: value?.currentUsing,
        },
        {
            name: 'Соответствие планировочных решений Объекта оценки указанным решениям в технической документации',
            value: value?.complianceLayout,
        },
        {
            name: 'Наличие неутвержденной перепланировки',
            value: value?.unapprovedLayout,
        },
        { name: 'Описание перепланировок', value: value?.layoutChanges },
        // {
        //     name: 'Фотографии',
        //     type: 'file',
        //     documentsGroup: value?.photoGroups,
        // },
    ];
    return <DataList items={inspectionInfo} isLoading={isLoading} />;
};
