import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthState = {
    user: string;
};

const slice = createSlice({
    name: 'auth',
    initialState: {
        user: '',
    },
    reducers: {
        setUser: (state, action: PayloadAction<string>) => {
            state.user = action.payload;
        },
    },
});

export const { setUser } = slice.actions;
export default slice.reducer;

export const currentUser = (state: { auth: AuthState }) => state.auth.user;
