import {
    DocumentRead,
    DocumentsGroupRead,
    DocumentsGroupTypeEnum,
    useApiV1CrmDocumentsCreateMutation,
    useApiV1CrmDocumentsDestroyMutation,
    useApiV1CrmDocumentsGroupsCreateMutation,
    useApiV1CrmDocumentsGroupsDestroyMutation,
    useApiV1CrmDocumentsGroupsPartialUpdateMutation,
    useApiV1CrmDocumentsPartialUpdateMutation,
} from '@ocenkatech/common/api';
import { FieldProps } from '@ocenkatech/common/dist/ui/Field';
import { useCallback } from 'react';
import { Controller, UseControllerProps, FieldValues } from 'react-hook-form';
import { IDocumentsGroup } from '../module/types';
import { DocumentsGroup } from './DocumentsGroup';

type ControllerDocumentsGroupProps<T extends FieldValues> = FieldProps<T> & {
    control: UseControllerProps<T>['control'];
    groups: IDocumentsGroup[];
    rules?: UseControllerProps<T>['rules'];
    typeGroup?: DocumentsGroupTypeEnum;
    onAfterAddGroup?: (group: DocumentsGroupRead) => Promise<void> | void;
    onAfterDeletedGroup?: (id: number) => Promise<void> | void;
};

export const ControllerDocumentsGroup = <T extends FieldValues>({
    control,
    formKey,
    rules,
    label,
    typeGroup,
    onAfterAddGroup,
    onAfterDeletedGroup,
    ...otherProps
}: ControllerDocumentsGroupProps<T>) => {
    const [createDocumentGroup] = useApiV1CrmDocumentsGroupsCreateMutation();
    const [partialUpdateDocumentsGroup] =
        useApiV1CrmDocumentsGroupsPartialUpdateMutation();
    const [destroyDocumentsGroup] = useApiV1CrmDocumentsGroupsDestroyMutation();
    const [createDocument] = useApiV1CrmDocumentsCreateMutation();
    const [destroyDocument] = useApiV1CrmDocumentsDestroyMutation();
    const [updateDocument] = useApiV1CrmDocumentsPartialUpdateMutation();

    const onAddDocumentGroup =
        useCallback(async (): Promise<DocumentsGroupRead> => {
            const group = await createDocumentGroup({
                documentsGroup: { name: 'Новая группа', type: typeGroup },
            }).unwrap();
            await onAfterAddGroup?.(group);

            return group;
        }, [createDocumentGroup, onAfterAddGroup, typeGroup]);

    const onRenameDocumentGroup = useCallback(
        async (id: number, name: string): Promise<void> => {
            await partialUpdateDocumentsGroup({
                id,
                patchedDocumentsGroup: { name },
            }).unwrap();
        },
        [partialUpdateDocumentsGroup],
    );

    const onDeleteDocumentGroup = useCallback(
        async (id: number) => {
            await destroyDocumentsGroup({ id }).unwrap();
            await onAfterDeletedGroup?.(id);
        },
        [destroyDocumentsGroup, onAfterDeletedGroup],
    );

    const onUploadDocument = useCallback(
        async (
            file: number,
            group: number,
            position: number,
        ): Promise<DocumentRead> => {
            return await createDocument({
                document: {
                    fileId: file,
                    group,
                    position,
                },
            }).unwrap();
        },
        [createDocument],
    );

    const onDeleteDocument = useCallback(
        async (id: number) => {
            await destroyDocument({ id }).unwrap();
        },
        [destroyDocument],
    );

    const onMoveFileGroup = useCallback(
        async (id: number, group: number, position: number) => {
            return await updateDocument({
                id,
                patchedDocument: { group, position },
            }).unwrap();
        },
        [updateDocument],
    );

    const onUpdatePositionFile = useCallback(
        async (id: number, position: number) => {
            return await updateDocument({
                id,
                patchedDocument: { position },
            }).unwrap();
        },
        [updateDocument],
    );

    return (
        <Controller
            control={control}
            name={formKey}
            rules={rules}
            render={({ field: { name } }) => (
                <DocumentsGroup
                    {...otherProps}
                    formKey={name}
                    label={label}
                    onAddNewGroup={onAddDocumentGroup}
                    onRenameGroup={onRenameDocumentGroup}
                    onDeleteGroup={onDeleteDocumentGroup}
                    onUploadFile={onUploadDocument}
                    onDeleteFile={onDeleteDocument}
                    onMoveFileGroup={onMoveFileGroup}
                    onUpdatePositionFile={onUpdatePositionFile}
                    required={!!rules?.required}
                />
            )}
        />
    );
};
