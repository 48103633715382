import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Button } from '@ocenkatech/common/ui/Button';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useImportUploadMutation } from 'entities/buildings';

type Inputs = {
    file: FileList;
};

export const Import: React.FC = () => {
    const { handleSubmit, register } = useForm<Inputs>();
    const [importUpload, { error, isLoading }] = useImportUploadMutation();

    const onSubmit = useCallback(
        (data: Inputs) => {
            const file = data.file.item(0);
            const formData = new FormData();
            if (file) {
                formData.append('file', file);
                importUpload(formData);
            }
        },
        [importUpload],
    );

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="file" {...register('file')} />
                {isLoading ? (
                    <ArrowPathIcon className="mr-2 h-8 w-8 animate-spin" />
                ) : (
                    <Button type="submit">Загрузить</Button>
                )}
            </form>
            <p>{JSON.stringify(error)}</p>
        </div>
    );
};
