import { LockClosedIcon } from '@heroicons/react/20/solid';
import { logo } from '@ocenkatech/common/assets';
import { KEEP_LOGGED_ID } from '@ocenkatech/common/const';
import { getValueInStorage, isErrorDataMessage } from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoginMutation } from 'entities/auth';

type Inputs = {
    username: string;
    password: string;
    keepLoggedIn: boolean;
};

export const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [login, { isSuccess, isLoading, error }] = useLoginMutation();

    const { handleSubmit, register } = useForm<Inputs>({
        defaultValues: {
            keepLoggedIn:
                getValueInStorage(KEEP_LOGGED_ID) === 'true' ? true : false,
        },
    });

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        async (data) => {
            login(data);
        },
        [login],
    );

    useEffect(() => {
        if (isSuccess) {
            navigate(location.state?.from || '/');
        }
    }, [isSuccess, navigate, location.state?.from]);

    useEffect(() => {
        if (isErrorDataMessage(error)) {
            alert(Object.values(error.data).join(', '));
        }
    }, [error]);

    return (
        <>
            <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
                <div className="w-full max-w-md space-y-8">
                    <div>
                        <img
                            className="mx-auto h-12 w-auto"
                            src={logo}
                            alt="Your Company"
                        />
                        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                            Авторизуйтесь в системе
                        </h2>
                    </div>
                    <form
                        className="mt-8 space-y-6"
                        onSubmit={handleSubmit(onSubmit)}>
                        <input
                            type="hidden"
                            name="remember"
                            defaultValue="true"
                        />
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="username" className="sr-only">
                                    Имя пользователя
                                </label>
                                <input
                                    id={'username'}
                                    type="text"
                                    {...register('username', {
                                        required: true,
                                    })}
                                    className="relative block w-full !rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Имя пользователя"
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Пароль
                                </label>
                                <input
                                    id="password"
                                    {...register('password', {
                                        required: true,
                                    })}
                                    autoComplete="current-password"
                                    type="password"
                                    className="relative block w-full !rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Пароль"
                                />
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="keepLoggedIn"
                                    {...register('keepLoggedIn')}
                                    type="checkbox"
                                    className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label
                                    htmlFor="keepLoggedIn"
                                    className="ml-2 block text-sm text-gray-900">
                                    Оставаться в системе
                                </label>
                            </div>

                            <div className="text-sm">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href="#"
                                    className="font-medium text-indigo-600 hover:text-indigo-500">
                                    Забыли свой пароль?
                                </a>
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                icon
                                className="group w-full"
                                loading={isLoading}>
                                <LockClosedIcon
                                    className="h-5 w-5 text-indigo-500 transition-colors group-hover:text-indigo-400"
                                    aria-hidden="true"
                                />
                                <span className="grow">Войти</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
