import { DocumentRead, UploadFileRead } from '@ocenkatech/common/api';
import { checkImageType } from '@ocenkatech/common/lib';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { DialogApp, DialogSize } from '@ocenkatech/common/ui/DialogApp';
import saveAs from 'file-saver';
import { FC, useCallback, useState } from 'react';

interface ShowImageProps {
    uploadFile: UploadFileRead | DocumentRead;
    name?: string;
}

export const ShowImage: FC<ShowImageProps> = ({ uploadFile }) => {
    const [open, setOpen] = useState(false);
    const linkFile =
        'name' in uploadFile ? uploadFile.file : uploadFile.file.file;
    const nameFile =
        'name' in uploadFile ? uploadFile.name : uploadFile.file.name;

    const downloadFile = useCallback((urlFile: string, nameFile: string) => {
        saveAs(urlFile, nameFile);
    }, []);

    const onShowImage = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (checkImageType(linkFile)) {
                setOpen(true);
            } else {
                downloadFile(linkFile, nameFile);
            }
        },
        [downloadFile, linkFile, nameFile],
    );

    return (
        <>
            <Button
                onClick={onShowImage}
                theme={ButtonTheme.OUTLINE}
                className="text-indigo-600 hover:text-indigo-500">
                Просмотреть
            </Button>
            <DialogApp
                open={open}
                setOpen={setOpen}
                title="Просмотр"
                size={DialogSize.LG}>
                {checkImageType(linkFile) ? (
                    <img src={linkFile} alt={nameFile} className="rounded-md" />
                ) : (
                    <h3 className="font-semibold">
                        Не удалось отобразить файл
                    </h3>
                )}
            </DialogApp>
        </>
    );
};
