import {
    CustomerRead,
    CustomerWrite,
    CustomerWriteRead,
    PatchedCustomerWrite,
    useApiV1CrmCustomersCreateMutation,
    useApiV1CrmCustomersPartialUpdateMutation,
} from '@ocenkatech/common/api';
import { getErrorMessage } from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import { Card } from '@ocenkatech/common/ui/Card';
import { DataItem, DataList } from '@ocenkatech/common/ui/DataList';
import { DialogApp, DialogSize } from '@ocenkatech/common/ui/DialogApp';
import { FC, useState, useCallback, memo, useId } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { CustomerForm } from './CustomerForm';

interface CustomerCardProps {
    isLoading?: boolean;
    isView?: boolean;
    defaultValues?: CustomerRead;
    className?: string;
    onAfterCreate?: (props: CustomerWriteRead) => void;
}

export const CustomerCard: FC<CustomerCardProps> = memo(
    ({ isLoading, isView, defaultValues, className = '', onAfterCreate }) => {
        const [isEdit, setIsEdit] = useState(false);
        const preparedValues: PatchedCustomerWrite | undefined = {
            ...defaultValues,
            passportAddressPage: defaultValues?.passportAddressPage?.id,
            passportMainPage: defaultValues?.passportMainPage?.id,
        };
        const formId = useId();

        const [
            create,
            {
                data: createdCustomer,
                isLoading: isCreating,
                error: errorCreate,
            },
        ] = useApiV1CrmCustomersCreateMutation();
        const [
            update,
            {
                data: updatedCustomer,
                error: errorUpdate,
                isLoading: isUpdating,
            },
        ] = useApiV1CrmCustomersPartialUpdateMutation();

        const onSave = useCallback<SubmitHandler<CustomerWrite>>(
            async (values) => {
                try {
                    if (isView) return;
                    if (defaultValues?.id) {
                        await update({
                            id: defaultValues.id,
                            patchedCustomerWrite: values,
                        }).unwrap();
                        setIsEdit(false);
                        alert('Данные клиента обновлены');
                    } else {
                        const result = await create({
                            customerWrite: values,
                        }).unwrap();
                        onAfterCreate?.(result);
                        setIsEdit(false);
                        alert('Создан новый Клиент');
                    }
                } catch (error) {
                    alert(
                        getErrorMessage(
                            error,
                            'Произошла ошибка при сохранении',
                        ),
                    );
                }
            },
            [create, defaultValues?.id, isView, onAfterCreate, update],
        );

        const onShowEdit = useCallback(() => {
            setIsEdit(true);
        }, []);

        const customerInfo: DataItem[] = [
            {
                name: 'Имя',
                value:
                    createdCustomer?.name ||
                    updatedCustomer?.name ||
                    defaultValues?.name,
            },
            {
                name: 'Телефон',
                value:
                    createdCustomer?.phone ||
                    updatedCustomer?.phone ||
                    defaultValues?.phone,
            },
            {
                name: 'Email',
                value:
                    createdCustomer?.email ||
                    updatedCustomer?.email ||
                    defaultValues?.email,
            },
        ];

        return (
            <>
                <Card onClick={onShowEdit} className={className}>
                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                        Клиент
                    </h3>
                    <div className="pt-6">
                        <DataList items={customerInfo} isLoading={isLoading} />
                    </div>
                </Card>

                <DialogApp
                    open={isEdit}
                    size={DialogSize.LG}
                    setOpen={setIsEdit}
                    title={
                        defaultValues?.id
                            ? 'Редактировать Клиента'
                            : 'Создать Клиента'
                    }
                    actionSuccess={
                        !isView && (
                            <Button
                                loading={isLoading || isCreating || isUpdating}
                                form={formId}
                                type="submit">
                                Сохранить
                            </Button>
                        )
                    }>
                    <CustomerForm
                        formId={formId}
                        defaultValues={
                            createdCustomer || updatedCustomer || preparedValues
                        }
                        isView={isView}
                        onSave={onSave}
                        isLoading={isLoading}
                        isProgress={isCreating || isUpdating}
                        error={errorCreate || errorUpdate}
                    />
                </DialogApp>
            </>
        );
    },
);
