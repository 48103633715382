import { DocumentRead, UploadFileRead } from '@ocenkatech/common/api';
import {
    canvasPreview,
    checkImageType,
    classnames,
    getCroppedImg,
    getFileNameByPath,
    useDebounceEffect,
} from '@ocenkatech/common/lib';
import { Button } from '@ocenkatech/common/ui/Button';
import { DialogApp, DialogSize } from '@ocenkatech/common/ui/DialogApp';
import {
    useRef,
    useState,
    useCallback,
    MouseEvent,
    ChangeEvent,
    useEffect,
} from 'react';
import ReactCrop, { PixelCrop, Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export function useCropModalFile(
    document: UploadFileRead | DocumentRead | null,
    uploadFile: (file: File) => Promise<void>,
) {
    const [originalFile, setOriginalFile] = useState<File>();
    const [imgSrc, setImgSrc] = useState('');
    const [imgName, setImgName] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [crop, setCrop] = useState<Crop>();
    const [isCropping, setIsCropping] = useState(false);
    const [isOpenCropModal, setIsOpenCropModal] = useState(false);

    useEffect(() => {
        if (document) {
            setImgSrc(document?.file?.toString() || '');
            setImgName(
                String(
                    'name' in document
                        ? document?.name
                        : getFileNameByPath(document.file?.toString()),
                ),
            );
        }
    }, [document]);

    const onShowCropModal = useCallback(
        (
            event:
                | MouseEvent<HTMLButtonElement>
                | ChangeEvent<HTMLInputElement>,
        ) => {
            event.stopPropagation();

            if (checkImageType(imgSrc)) {
                setIsOpenCropModal(true);
                setCrop(undefined);
            } else if (
                'files' in event.target &&
                event.target.files &&
                event.target.files.length > 0 &&
                checkImageType(event.target.files[0].name)
            ) {
                setOriginalFile(event.target.files[0]);
                const reader = new FileReader();
                reader.addEventListener('load', () =>
                    setImgSrc(reader.result?.toString() || ''),
                );
                reader.readAsDataURL(event.target.files[0]);
                setImgName(event.target.files[0].name);
                setIsOpenCropModal(true);
                setCrop(undefined);
            }
        },
        [imgSrc],
    );

    const onCropImage = async () => {
        try {
            setIsCropping(true);
            if (completedCrop) {
                const blob = await getCroppedImg(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                );

                await uploadFile(
                    new File([blob], imgName || imgSrc, {
                        type: blob.type,
                        lastModified: Date.now(),
                    }),
                );
            } else if (originalFile) {
                await uploadFile(originalFile);
            }
        } catch (e) {
        } finally {
            setImgName('');
            setImgSrc('');
            setIsCropping(false);
            setIsOpenCropModal(false);
        }
    };

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                );
            }
        },
        [completedCrop],
        100,
    );

    const ModalCrop = (
        <DialogApp
            open={isOpenCropModal}
            setOpen={setIsOpenCropModal}
            title="Обрезать изображение"
            size={DialogSize.FULL}
            actionSuccess={
                <Button onClick={onCropImage} loading={isCropping}>
                    Обрезать
                </Button>
            }>
            <div className="relative z-10 flex justify-center">
                {!!checkImageType(imgSrc) && (
                    <ReactCrop
                        className={classnames(
                            {
                                'pointer-events-none': isCropping,
                            },
                            'gap-2 overflow-hidden rounded-md',
                        )}
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}>
                        {isCropping && (
                            <div className="absolute inset-0 animate-pulse bg-gray-200 opacity-80" />
                        )}
                        <img
                            crossOrigin="anonymous"
                            ref={imgRef}
                            src={imgSrc}
                            alt="Crop me"
                        />
                    </ReactCrop>
                )}
                {!!completedCrop && (
                    <div className="pointer-events-none absolute opacity-0">
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                )}
            </div>
        </DialogApp>
    );

    return { onShowCropModal, ModalCrop };
}
