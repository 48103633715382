import {
    CloudArrowUpIcon,
    PaperClipIcon,
    PhotoIcon,
} from '@heroicons/react/24/solid';
import { UploadFileRead, DocumentRead } from '@ocenkatech/common/api';
import {
    checkImageType,
    classnames,
    getErrorMessage,
    htmlFor,
} from '@ocenkatech/common/lib';
import { Button, ButtonTheme } from '@ocenkatech/common/ui/Button';
import { Field, FieldChildrenProps } from '@ocenkatech/common/ui/Field';
import { Loader } from '@ocenkatech/common/ui/Loader';
import {
    ChangeEvent,
    ReactElement,
    Ref,
    forwardRef,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FieldValues } from 'react-hook-form';
import { useCropModalFile } from '../../../lib/react/useCropModalFile';
import { useUploadFiles } from '../../../lib/react/useUploadFiles';
import { ShowImage } from './ShowImage';

type DropFileProps<T extends FieldValues> = Omit<
    FieldChildrenProps<T>,
    'register'
> & {
    file?: UploadFileRead | DocumentRead | null;
    onUpload: (d: UploadFileRead) => void;
    onRemove?: () => void;
    isEdit?: boolean;
    isView?: boolean;
    accept?: string;
    titleAccept?: string;
};

function DropFileInner<T extends FieldValues>(
    {
        formKey,
        label,
        error,
        required,
        size,
        fieldClassName,
        file,
        onUpload,
        isEdit,
        isView,
        isLoading,
        onRemove,
        accept,
        titleAccept = '',
    }: DropFileProps<T>,
    ref?: React.Ref<HTMLInputElement>,
) {
    const [uploadedFile, setUploadedFile] = useState<
        UploadFileRead | DocumentRead | null
    >(null);
    useEffect(() => {
        if (file) {
            setUploadedFile(file);
        }
    }, [file]);

    const [uploadFile, { isLoading: isUploading }] = useUploadFiles();

    const onUploadFile = useCallback(
        async (file: File) => {
            try {
                const result = await uploadFile(file);

                onUpload(result);
                setUploadedFile(result);
            } catch (error) {
                alert(getErrorMessage(error, 'Не удалось загрузить файл'));
            }
        },
        [uploadFile, onUpload],
    );

    const remove = useCallback(() => {
        onRemove?.();
        setUploadedFile(null);
    }, [onRemove]);

    const { onShowCropModal, ModalCrop } = useCropModalFile(
        uploadedFile,
        onUploadFile,
    );

    const onManualUpload = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.files?.length && e.currentTarget.files[0]) {
                onUploadFile(e.currentTarget.files[0]);
            }
        },
        [onUploadFile],
    );

    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: [NativeTypes.FILE],
            drop: (item: { files: File[] }) => {
                onUploadFile(item.files[0]);
            },
            collect: (monitor: DropTargetMonitor) => {
                return {
                    isOver: monitor.isOver(),
                };
            },
        }),
        [onUploadFile],
    );

    return (
        <>
            <Field
                formKey={formKey}
                label={label}
                fieldClassName={fieldClassName}
                size={size}
                required={required}
                error={error}
                isShowError>
                {uploadedFile ? (
                    <div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                            <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                <div className="flex w-0 flex-1 items-center">
                                    {isLoading || isUploading ? (
                                        <Loader className="!size-5" />
                                    ) : checkImageType(
                                          String(
                                              'name' in uploadedFile
                                                  ? uploadedFile.name
                                                  : uploadedFile?.file,
                                          ),
                                      ) ? (
                                        <button
                                            onClick={onShowCropModal}
                                            type="button"
                                            className="h-10 w-10 flex-shrink-0"
                                            disabled={isLoading || isUploading}>
                                            {uploadedFile.file && (
                                                <img
                                                    src={String(
                                                        uploadedFile.file,
                                                    )}
                                                    alt="Preview"
                                                    className="h-6 w-6 rounded-md object-contain"
                                                />
                                            )}
                                        </button>
                                    ) : (
                                        <PaperClipIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                        <span className="truncate font-medium">
                                            {String(
                                                'name' in uploadedFile
                                                    ? uploadedFile.name
                                                    : uploadedFile?.file,
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                    {uploadedFile && (
                                        <ShowImage uploadFile={uploadedFile} />
                                    )}
                                    {isEdit && !isView && (
                                        <>
                                            <span
                                                className="text-gray-200"
                                                aria-hidden="true">
                                                |
                                            </span>
                                            <label
                                                htmlFor={htmlFor(formKey)}
                                                className="cursor-pointer text-gray-500 hover:text-gray-300">
                                                Изменить
                                            </label>
                                        </>
                                    )}
                                    {onRemove && !isView && (
                                        <>
                                            <span
                                                className="text-gray-200"
                                                aria-hidden="true">
                                                |
                                            </span>
                                            <Button
                                                theme={ButtonTheme.OUTLINE}
                                                className="text-red-500 hover:text-red-300"
                                                onClick={remove}>
                                                Удалить
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                ) : isView ? (
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <h4 className="font-semibold">Файл отсутсвует</h4>
                    </div>
                ) : (
                    <div
                        className={classnames(
                            'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10',
                            {
                                'border-2 border-solid border-indigo-600':
                                    isOver,
                            },
                        )}
                        ref={drop}>
                        <div className="text-center">
                            {isOver ? (
                                <>
                                    <CloudArrowUpIcon
                                        className="mx-auto h-12 w-12 text-gray-300"
                                        aria-hidden="true"
                                    />
                                    <div className="mb-4 mt-4 flex text-sm leading-6 text-gray-600">
                                        <p>Отпустите чтобы загрузить файл</p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {isLoading || isUploading ? (
                                        <Loader className="mx-auto !h-12 !w-12 text-gray-300" />
                                    ) : (
                                        <PhotoIcon
                                            className="mx-auto h-12 w-12 text-gray-300"
                                            aria-hidden="true"
                                        />
                                    )}
                                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                        <label
                                            htmlFor={htmlFor(formKey)}
                                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                                            <span>Загрузите файл</span>
                                        </label>
                                        <p className="pl-1">
                                            или перетащите его сюда
                                        </p>
                                    </div>
                                    <p className="text-xs leading-5 text-gray-600">
                                        {titleAccept}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <input
                    ref={ref}
                    onChange={onManualUpload}
                    name={htmlFor(formKey)}
                    id={htmlFor(formKey)}
                    accept={accept}
                    disabled={isLoading || isUploading}
                    type="file"
                    className="sr-only"
                />
            </Field>
            {ModalCrop}
        </>
    );
}

export const DropFile = forwardRef(DropFileInner) as <T extends FieldValues>(
    p: DropFileProps<T> & { ref?: Ref<HTMLInputElement> },
) => ReactElement;
